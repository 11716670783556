<template>
    <div id="removal">
        <div class="relative">
            <div class="title">
                图文去重
            </div>
            <section class="full">
                <div style="    font-weight: bold;">*上传图片 </div>
                <div v-if="imgUrl == ''" class="fulldiv" @dragover.prevent @drop="onDrop" @click="onButtonClick"
                    style="padding: 40px 0;">
                    <input type="file" accept="image/*" @change="onFileChange" style="display: none;" ref="fileInput">
                    <img class="flieimg" src="~@/assets/Toolsimg/upload.svg" alt="上传">
                    <p class="">点击/拖拽上传图片</p>
                    <p style="font-size: 13px;color: #999999;">支持8M内JPG/PNG图片</p>
                </div>
                <div v-else class="fulldiv" @click="onButtonClick" style="height: 190px;">
                    <input type="file" accept="image/*" @change="onFileChange" style="display: none;padding: 40px 0;"
                        ref="fileInput">
                    <img class="newimg" :src="imgUrl" alt="图片">
                </div>
            </section>

            <section class="full">
                <div style="font-weight: bold;">选择处理方式：</div>
                <div style="display: grid; grid-template-columns: repeat(4, 1fr);">
                    <div v-for="(item, index) in selecttype" :key="index" style="" class="switchUl"
                        :class="{ 'switchUl-active': selectone.includes(item.value) }" @click="ontype(item)">
                        <img v-if="selectone.includes(item.value)" :src="item.activesrc" alt=""
                            style="width: 40px;height: 40px;transform: scale(0.8);">
                        <img v-else :src="item.src" alt="" style="width: 40px;height: 40px;transform: scale(0.8);">
                        <div style="font-size: 14px;">{{ item.label }}</div>
                    </div>
                </div>
            </section>
            <div class="w-full">
                <button style="" class="fullbtn" @click="onSubmit()" :disabled="loading == 1"
                    :class="{ 'fullbtn-led': loading == 1 }">开始处理</button>
            </div>
        </div>
        <div class="canvasPc">
            <div v-if="loading == 0" style="display: flex;align-items: center;">
                <img src="~@/assets/Toolsimg/uploadbig.svg" alt="" width="300" height="200">
                <div>
                    <p style="font-size: 16px;color: #333333;">生成的图片将会显示在这里</p>
                    <p style="font-size: 13px;color: #999999;">左侧上传你的图片，创建你的图片吧</p>
                </div>
            </div>
            <div v-else style=" height: 100%;display: flex;justify-content: center;align-items: center;">
                <div v-if="loading == 1"
                    style="display: flex;justify-content: center;flex-direction: column;align-items: center;">

                    <img src="~@/assets/Toolsimg/test.gif" alt="" style="width: 30px;height: 100%;object-fit: contain;">

                    <div style="font-weight: bold;margin: 5px;padding-top: 5px;margin-top: -1px;background: #F8F8FB;">生成中
                    </div>
                    <div style="color: rgba(134,147,171,1);">您可稍后回来查看结果</div>
                </div>
                <div v-else style="    height: 100%;">
                    <img :src="newimgUrl" alt="" style="width: 100%;height: 100%;object-fit: contain;border-radius: 15px;">
                </div>
            </div>

            <div class="flex-center">
                <button class="download" :disabled="loading != 2" :class="{ 'download-led': loading != 2 }" @click="Cdownload()">下载</button>

            </div>
        </div>

    </div>
</template>

<script>
import { imgupload, toDuplication, tolookRes } from '@/api/utils/index.js';

export default {
    data() {
        return {
            timer: null,//保存临时定时器
            remark: "",//备注保存查询的图片进度的临时id
            loading: 0,//是否展示生成中 0未开始 1生成中 2生成完成
            download: false,//是否展示下载按钮
            selectone: [1],
            Bflieimg: null,
            newimgUrl: "",//保存处理后的url
            imgUrl: "",//保存用户上传的url
            selecttype: [
                {
                    label: "变清晰",
                    value: 1,
                    src: "https://top.xhsbds.com/assets/img/deduplication/bqx.svg",
                    activesrc: "https://top.xhsbds.com/assets/img/deduplication/bqx-xz.svg",
                    state: false,
                },
                {
                    label: "对比度",
                    value: 2,
                    src: "https://top.xhsbds.com/assets/img/deduplication/jaw.svg",
                    activesrc: "https://top.xhsbds.com/assets/img/deduplication/jaw-xz.svg",
                    state: false,
                },
                {
                    label: `调颜色`,
                    value: 3,
                    src: "https://top.xhsbds.com/assets/img/deduplication/ts.svg",
                    activesrc: "https://top.xhsbds.com/assets/img/deduplication/ts-xz.svg",
                    state: false,
                },
                {
                    label: "改尺寸",
                    value: 4,
                    src: "https://top.xhsbds.com/assets/img/deduplication/gcc.svg",
                    activesrc: "https://top.xhsbds.com/assets/img/deduplication/gcc-xz.svg",
                    state: false,
                },
                {
                    label: "AI扩图",
                    value: 5,
                    src: "https://top.xhsbds.com/assets/img/deduplication/AIkt.svg",
                    activesrc: "https://top.xhsbds.com/assets/img/deduplication/AIkt-xz.svg",
                    state: false,
                },
            ],
            temptext:{
                    "id": 8,
                    "group_id": 0,
                    "username": "本地薯4572",
                    "nickname": "本地薯4572",
                    "mobile": "18715845727",
                    "weixin": null,
                    "avatar": "",
                    "company_name": "",
                    "authorization": 0,
                    "share_code": "",
                    "identity": 1,
                    "level": 0,
                    "gender": 0,
                    "aitooltime": null,
                    "source": 1,
                    "loginfailure": 0,
                    "prevtime": null,
                    "status": 1
            }
        }
    },
    methods: {
        onButtonClick() {
            this.$refs.fileInput.click();
        },
        /**
         * 点击上传图片
         * @param {*} e 
         */
        onFileChange(e) {
            const files = e.target.files;
            if (!files.length) return;
            // 你可以在这里处理文件，例如上传它们
            console.log(files, "文件1");
            // 判断文件上传格式
            if (files[0].size > 1024 * 1024 * 8) {
                this.$message.error("上传图片不能大于8M");
                return;
            }
            // 判断文件上传格式
            if (files[0].type !== "image/jpeg" && files[0].type !== "image/png" && files[0].type !== "image/jpg") {
                this.$message.error("上传图片格式不正确,仅支持jpeg、png、jpg格式");
                return;
            }
            this.Bflieimg = files[0];
            this.imgUrl = URL.createObjectURL(files[0]);
        },
        /**
         * 拖拽上传
         * @param {*} e 
         */
        onDrop(e) {
            e.preventDefault();
            const files = e.dataTransfer.files;
            if (!files.length) return;
            // 你可以在这里处理文件，例如上传它们
            console.log(files, "文件2");
            
            // 判断文件上传格式
            if (files[0].size > 1024 * 1024 * 8) {
                this.$message.error("上传图片不能大于8M");
                return;
            }
            // 判断文件上传格式
            if (files[0].type !== "image/jpeg" && files[0].type !== "image/png" && files[0].type !== "image/jpg") {
                this.$message.error("上传图片格式不正确,仅支持jpeg、png、jpg格式");
                return;
            }
            this.Bflieimg = files[0];
            this.imgUrl = URL.createObjectURL(files[0]);
        },
        // 多选内容
        ontype(item) {
            if (this.selectone.includes(item.value)) {
                //如果存在移除this.selectone中的值
                this.selectone = this.selectone.filter((i) => i != item.value);
            } else {
                this.selectone.push(item.value);
            }
        },
        /**
         * 开始处理
         * @param {*} e 
         */
        async onSubmit() {
            //判断是否是登录的用户
            if(!localStorage.getItem("token").includes("Bearer ")){
                this.$message.error("您还未登录");
            }
            //判断用户权限
            let isUse = await this.$utils.getaiCanUse();
            if(!isUse){
                this.$message.error('您没有权限使用AI功能，请检查是否登录/小程序开通套餐');
                return ;
            }


            let level = this.$store.userinfo?.identity
            if(!level){ 
                //调info接口
                let res = await this.$newapi.getUserInfo();
                level = res.identity;
            }




            //判断是否有图片
            if (this.imgUrl == "") {
                this.$message.error("请上传图片");
                return;
            } else if (this.selectone.length == 0) {
                this.$message.error("请选择处理方式");
                return;
            }
            this.loading = 1;//生成中
            let formData = new FormData();
            formData.append('file', this.Bflieimg);
            const res = await imgupload(formData);
            const fullurl = res.data.data.fullurl;
            const ap = this.selectone.includes(5) ? res.data.data.url : "";
            if (!fullurl) {
                this.$message.error("上传失败");
                return;
            }
            //后端没处理，前端处理把5放到最前面
            this.selectone = this.selectone.sort((a, b) => {
                if (a === 5) return -1;
                if (b === 5) return 1;
                return a - b;
            });
            console.log("res",res.data.data.fullurl, "图片地址",ap,this.selectone);
            // console.log(this.imgUrl,res.data.data.fullurl, "图片地址");
            const Task = await toDuplication({ img: fullurl, step: this.selectone.toString(), open_id: this.$store.state.openid, "ap": ap});
            console.log(Task, Task.data.remark, "加入任务完成");
            this.remark = Task.data.remark;
            // this.loading = 2;//生成完成
            this.myInterval();
        },
        /**
         * 每个2秒轮询查询接口是否处理完成，完成后展示下载按钮
         *
         */
        async myInterval() {
            this.timer = setInterval(async () => {
                try{
                    const data = await tolookRes({ remark: this.remark, type: 3 });
                console.log(data, "轮询查询");
                if (data.data.state == "wait") {
                    // this.myInterval();
                } else if (data.data.state == "ok") {
                    this.$message.success("处理完成");
                    this.newimgUrl = data.data.img;
                    this.download = true;
                    this.loading = 2;//生成完成
                    clearInterval(this.timer);
                } else {
                    this.$message.error("处理失败,请换张图片重试");
                    clearInterval(this.timer);
                    this.loading = 0;
                }
                }catch{
                    this.$message.error("处理失败,请换张图片重试");
                    clearInterval(this.timer);
                    this.loading = 0;
                }

            }, 4000);
        },
        /**
         * 下载图片
         */
         Cdownload() {
            
            // result为返回的blob文件数据 可自行做操作
            // 以下为下载操作 ----
            // let a = document.createElement('a')
            // const imageUrl =url
            // a.setAttribute('href', imageUrl)
            // a.setAttribute('download', Date.now() + 'matting.png')
            // a.click();process.env.VUE_APP_Server
            
            let url = window.appConfig.baseUrl[process.env.NODE_ENV] +`/imageUrl/?url=${this.newimgUrl}`;
            fetch(url)
            .then(response => response.blob())
            .then(blob => {
                // 创建一个Blob URL
                const blobUrl = URL.createObjectURL(blob);

                // 创建一个a元素并设置下载链接
                let a = document.createElement('a');
                a.href = blobUrl;
                a.download = Date.now() + '.png';

                // 模拟点击a元素进行下载
                a.click();

                // 释放Blob URL，以防内存泄漏
                URL.revokeObjectURL(blobUrl);
            })
            .catch(error => {
                console.error('下载失败：', error);
            });

        },
    },
}
</script>

<style lang="less" scoped>
@bordercolor: 1px solid #EBEEF5;

#removal {
    border-top: @bordercolor;
    width: 100wh;
    height: calc(100vh - 120px);
    // background: #f1a2a2;
    display: flex;

    .relative {
        position: relative;
        width: 20vw;
        padding: 20px;
        box-sizing: border-box;
        height: 100%;

        .title {
            font-size: 24px;
            font-weight: bold;
            color: #000;
            text-align: left;
            // margin-top: 20px;
        }

        .full {

            padding: 16px;
            margin-top: 16px;
            background-color: #F3F4F9;
            width: 100%;
            box-sizing: border-box;
            border-radius: 10px;

            .switchUl {

                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;
                margin: 5px;
                padding: 5px;
                border-radius: 8px;
                border: 1px solid #fff;
                background-color: #fff;
                cursor: pointer;

            }

            .switchUl-active {
                border: 1px solid #FE2043;
                color: #FE2043 !important;
            }
        }

        .flieimg {
            height: 60px;
            width: 60px;
        }

        .fulldiv {
            height: 110px;
            margin-top: 16px;

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background-color: #fff;
            border-radius: 6px;
            border: 1px dashed #000;
            cursor: pointer;

            p {
                margin: 6px 0 0 0;
                font-size: 16px;
                color: #333333;
            }

            .newimg {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }

        .w-full {
            width: 100%;
            position: absolute;
            left: 0px;
            bottom: -20px;
            background: #fff;
            border-top: @bordercolor;
            padding: 20px;
            box-sizing: border-box;
            padding-bottom: 40px !important;

            .fullbtn {
                padding: 12px;
                background-color: #FE2043;
                color: #fff;
                font-size: 16px;
                font-weight: bold;
                border: none;
                border-radius: 6px;
                width: 100%;
            }

            .fullbtn:hover {
                transition: all 0.3s;
                background-color: #FFDADE;
                color: #fff;
            }

            .fullbtn-led {
                transition: all 0.3s;
                background-color: #FFDADE;
                color: #fff;
            }
        }
    }

    .canvasPc {
        position: relative;
        border-left: @bordercolor;
        background-color: #F8F8FB;
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;

        .download {
            position: absolute;
            top: 20px;
            right: 20px;
            border-radius: 10px;
            background-color: #FE2043;
            font-size: 16px;
            color: #fff;
            border: none;
            padding: 10px 30px;
            cursor: pointer;
        }

        .download-led {
            background-color: #FFDADE;
            color: #fff;
            cursor: not-allowed;
            font-size: 14px;
        }
    }
}</style>