<template>
  <div class="main">
    <!-- 添加背景图 -->
    <!-- <img src="@/assets/image/bg.png" alt="" class="bg-img" style="width: 100%;height: 100%;"> -->

    <div class="video-container">

      <img v-if="!videoLoaded" src="https://wechat.xhsbds.com/images/index/pcbg.png" alt="" class="bg-img" style="width: 100%;height: 100%;position: absolute;  top: 0;  left: 0;">
      <video autoplay muted loop id="background-video" ref="videoRef" @loadeddata="handleLoadedData">
        <source src="https://wechat.xhsbds.com/images/index/bg.mp4" type="video/mp4">
      </video>
    </div>
    <div class="head-bar" style="background: rgba(255, 0, 0, 0);">
      <CompanyLogo />
      <div class="nav">
      </div>
      <!-- <div class="nav">
        <button class="register-btn" @click="loginDialog(2)">立即登录</button>
      </div> -->

    </div>
    <div class="content">
      <div class="title">{{ text1 }}</div>
      <div class="desc">{{ text2 }}</div>
      <div class="actions">
        <button class="login-btn" @click="loginDialog(2)">服务商进入</button>
        <button class="login-btn" @click="loginDialog(3)"> 商家进入 </button>
      </div>
    </div>

    <!-- 登录弹窗 -->
    <el-dialog class="dialog-center" title="" :visible.sync="centerDialogVisible" width="420px"  :close-on-click-modal="false" center>
      <el-form ref="myForm" id="myForm" :model="login" @submit.native.prevent="handleLogin"  @keyup.enter.native="handleEnter" >

        <div class="dialog-title mar-23">欢迎加入本地薯</div>
        <div>
          <el-radio-group v-model="loginRole" size="mini" class="dialog-title" text-color="#fff" fill="#FE2749">
            <el-radio-button label="2">服务商登录</el-radio-button>
            <el-radio-button label="3">
              <span>
                &nbsp;&nbsp;商家登录&nbsp;&nbsp;
              </span>
              </el-radio-button>
          </el-radio-group>
        </div>
        <el-tabs v-model="activeName" @tab-click="handleClick" class="tabs-center">
          <el-tab-pane label="账号密码" name="first">
            <el-input ref="input1" v-model="login.username" placeholder="公司简称" class="mar-23" clearable></el-input>
            <el-input ref="input2" id="password" v-model="login.password" placeholder="密码" class="mar-23" show-password></el-input>
          </el-tab-pane>
        </el-tabs>
        <div>
          <el-button class="mar-23 dyn" type="danger" @click="handleLogin()">确 定</el-button>
          <div style="color:#999;text-align: center;font-size: 12px;">注册登录即代表同意《本地薯共创平台协议》</div>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>

// import img from '@/assets/images/bg.png'
import { loginto } from '@/api/utils/index.js';
import CompanyLogo from '@/components/CompanyLogo/index.vue'

import { Message } from 'element-ui';
export default {
  data() {
    return {
      videoLoaded: false,
      loginRole: "2",
      activeName: 'first',
      centerDialogVisible: false,
      src: '',
      name: 'home',
      login: {
        username: null,
        password: null
      },
      text1: "本地薯服务商共创平台",
      text2: "专注本地生活技术赋能",
      title: '技术服务中心',
    }
  },
  mounted() {
    this.$refs.videoRef.addEventListener('loadeddata', this.handleLoadedData);
  },
  beforeDestroy() {
    this.$refs.videoRef.removeEventListener('loadeddata', this.handleLoadedData);
  },
  components: {
    CompanyLogo
  },
  methods: {
    handleLoadedData() {
      this.videoLoaded = true;
    },
    handleEnter(event) {
      const inputs = this.$refs.myForm.$el.getElementsByTagName('input');
      if(event.target.id == "password"){
        this.handleLogin();
      }
      const currentInput = event.target;

      for (let i = 0; i < inputs.length; i++) {
        if (inputs[i] === currentInput && i < inputs.length - 1) {
          inputs[i + 1].focus();
          break;
        }
      }
      if (event.target === this.$refs.input2.$el) {
        this.handleLogin();
      }
    },
    //登录
    async loginDialog(userid) {
        let user = {
          "token": "1008611",
          "nickname": userid==2?"服务商":"商家",
          "code": "1008611",
          "authorization": "1",
          "price": null,
          "level": userid,
          "avatar": "/assets/img/avatar.png",
          "email": "test@qq.com",
          "mobile": "15888888888",
          "status": "normal"
        }
        //判断localstorage是否有token
        let temp = await window.localStorage.getItem('token');
        if (temp) {
            user.token = temp;
        }else{
          //用时间戳通过md5加密一个token
          user.token = new Date().getTime();
          await window.localStorage.setItem('token', user.token);
        }

        this.$store.commit('setOpenid',new Date().getTime());
        window.localStorage.setItem('token', user.token);
        localStorage.setItem('user', JSON.stringify(user));
      localStorage.setItem('loginRole', userid);

      if(this.$router.currentRoute.fullPath != '/index'){
          this.$router.push('/index');
      }

      // this.centerDialogVisible = true;
      // this.loginRole = userid + '';
      // console.log(userid);
    },
    // 切换登录方式
    handleClick(tab) {
      console.log(tab)
    },
    async handleLogin() {
      var params = {
        username: this.login.username,
        password: this.login.password,
        level: this.loginRole
      }
      console.log(params, "登录参数");
      await loginto(params).then(res => {
        console.log(res, "登录结果");
        if (res.status != 200) {
          Message({
            message: res.msg,
            type: "error",
            duration: 3 * 1000,
          })
        } else {
          Message({
            message: "登录成功，欢迎您",
            type: "success",
            duration: 3 * 1000,
          })
          window.localStorage.setItem('user', JSON.stringify(res.data));
          window.localStorage.setItem('token', res.data.token);
          this.$router.push('/index');
        }

      }).catch(err => {
        Message.error("登录失败，请联系管理员");
        console.log(err);
      })


    }
  }
}
</script>

<style lang="less" scoped>
@left-width: 150px;
@line-height: 65px;

.main {
  color: #fff;
  position: fixed;
}

.video-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  overflow: hidden;
}

#background-video {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
  height: auto;
}

.bg-img {
  position: absolute;
  top: 0;
  left: 0;
  // width: calc(100%);
  min-width: 100%;
  // height: 100%;
  // min-height: 100%;
  z-index: -1;
}

.head-bar {

  padding-left: 5vw;
  padding-right: 5vw;
  position: relative;
  height: @line-height;
  // background-color: #000;
  width: 100%;
  min-width: 850px;
  display: flex;
  align-content: center;
  align-items: center;

  .nav {
    width: @left-width;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .register-btn {
      height: 32px;
      border-radius: 16px;
      width: 100px;
      background: #ff2442;
      color: #fff;
      border: none;
      cursor: pointer;
    }
  }
}



.content {
  position: absolute;
  left: @left-width;
  top: calc(50%);
  top: clamp(300px, 30%, 400px);
  // color: #fff;
  font-weight: 500;

  .title {
    font-size: 36px;
  }

  .desc {
    font-size: 64px;
    margin-top: 30px;
  }

  .actions {
    display: flex;
    grid-gap: 24px;
    gap: 24px;
    margin-top: 42px;

    .login-btn {
      width: 240px;
      height: 48px;
      font-size: 16px;
      border-radius: 24px;
      color: #fff;
      border: 1px solid #fff;
      background: rgba(0, 0, 0, 0);
      cursor: pointer;
    }
  }
}

.dialog-center {
  .el-dialog {
    border-radius: 20px;
    position: absolute;
    top: 5vw;
    right: 10vw;
  }

  .el-dialog__body {
    padding: 0px 75px 25px 75px !important;

    color: #333;

    .tabs-center {
      margin-top: 15px;
      .el-tabs__nav-wrap::after {
        display: none;

      }
      .el-tabs__item{
        transform: translate(0px, 5px);
      }
      .el-tabs__item.is-active {
        color: #333;
      }

      .el-tabs__active-bar {
        background-color: #FE2749;
        border-radius: 2px;
      }
      .el-icon-close:before{
        font-size: 20px;
      }

    }
  }

  .el-input.is-active .el-input__inner,
  .el-input__inner:focus {
    border-color: #ff2442 !important;
    outline: 0;
  }

  .el-dialog__headerbtn .el-dialog__close:hover {
    color: #ff2442;
  }

  .el-radio-group {
    .el-radio-button__inner:hover {
      color: #ff2442;
    }

    .el-radio-button__inner {
      color: #333;
    }
  }

  .dialog-title {
    font-size: 24px;
    width: 100%;
    text-align: center;
  }

  .mar-23 {
    margin-bottom: 23px;
  }

  .dyn {
    width: 100%;
    border-radius: 50px;
    background-color: #FE2749;
    border-color: #FE2749;
    font-size: 16px;
    margin-top: 20px;
  }
}
</style>
