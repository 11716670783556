import axios from 'axios'
import { Message } from 'element-ui';
import newLogin from '@/views/Login/newLoginApi.js';
import store from '@/store/store.js'
// import config from '@/api/config.js'

/**
 * 新的接口
 */
var api = axios.create({
    // baseURL: window.appConfig.newbaseUrl[process.env.NODE_ENV],//请求后端地址
    // baseURL: config.newbaseUrl[process.env.NODE_ENV],//请求后端地址
    timeout: 20000,//请求超时
    withCredentials: true, // 是否发送 cookie
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
    },
});


// 请求拦截器
api.interceptors.request.use(
    config => {
        config.baseURL = window.appConfig.newbaseUrl[process.env.NODE_ENV];//请求后端地址
        
        // console.log("请求config",config);
        // console.log("哈哈哈哈",config);
        // 请求发送前做一些处理
        // 每个请求携带token
        let token = localStorage.getItem('token');
        if (token && token.includes('Bearer')){
            config.headers['Authorization'] = `${token}`;
        }
        // config.headers['Authorization'] = `Bearer asldfawelagalrkna.alwrgkja;lwegnafjasld.fjfawegaefaregieg`
        return config;
    },
    error => {
        // console.log(error, "请求拦截");
        // 请求出错时处理
        return Promise.reject(error);
    }
);

// 响应拦截器
api.interceptors.response.use(
    /**
     * 如果需要获取 http 信息如 headers 或者 status
     * 返回 response => response
     */
    response => {
        /**
         * 根据自定义代码判断请求状态
         * 下面只是一个示例
         * 你也可以根据 HTTP Status Code 判断状态
         */
        // 响应数据处理
        const res = response.data;
        // console.log(response, response.headers.Authorization,"响应拦截");
        //如果请求头包含token，更新token
        if(response.headers.authorization){
            // console.log("更新token",response.headers.Authorization);
            localStorage.setItem('token',response.headers.Authorization);
        }

        if (response.status === 200) {
            return res
        } 
        else {
            Message.warning('联系管理员');
            // 响应错误处理
            Promise.reject(new Error(res.msg || 'Error'));

        }


    },
    error => {

        // console.log('错误拦截1111', error)

        // console.log("重新登录",error,code);
        // 响应出错时处理
        if(error.code =="ERR_BAD_REQUEST"){
            let code = error.response.data.code;

            // console.log("重新登录",error,code);
            // 请求参数错误
            if(code == 400){
                Message({
                    message: error.response.data.msg,
                    type: "error",
                    duration: 3 * 1000,
                })
                return ;
            }
            if(code == 401 || code == 402 ){
                // 重新登录
                newLogin();
                //清空缓存
                localStorage.setItem('token', new Date().getTime());
                localStorage.removeItem('userInfo');
                store.commit('setToken', '');
                store.commit('setUserInfo', {});
                // 未登录，登录过期，跳转到登录页面
                Message({
                    message: error.response.data.msg,
                    type: "error",
                    duration: 3 * 1000,
                })
                return error.response.data;
            }
            if(code == 403){
                Message({
                    message: error.response.data.msg,
                    type: "error",
                    duration: 3 * 1000,
                })
                return ;
            }
            if(code == 404){
                Message({
                    message: error.response.data.msg,
                    type: "error",
                    duration: 3 * 1000,
                })
                return ;
            }
            if(code == 500){
                Message({
                    message: error.response.data.msg,
                    type: "error",
                    duration: 3 * 1000,
                })
                return ;
            }

        }


        //网络超时异常处理
        if (error.code === 'ECONNABORTED' || error.message === "Network Error" || error.message.includes("timeout")) {
            // 超时处理,引入ellement-ui message组件
            Message({
                message: '请求超时，请稍后重试',
                type: "error",
                duration: 3 * 1000,
            })
        }
        return Promise.reject(error);
    }
);

export default api;