<template>
  <div  class="top-content" @click="goTo(itemAI)">
    <el-popover
    placement="top-start"
    :title="this.itemAI.name"
    width="200"
    trigger="hover"
    :content="this.itemAI.intro">
    <div slot="reference">
            <div class="top-img">
                <img :src="this.itemAI.icon" alt="logo" class="logo" >
            </div>
            <div class="top-text">{{itemAI.name}}</div>
        </div>
  </el-popover>




  </div>
</template>

<script>
import { tempgoTo } from "@/utils/utils"
export default {
    props: {
        itemAI: {
            type: Object,
            default: () => {
                return {
                    icon: "https://top.xhsbds.com/uploads/20231213/75c8cc1a0a864eac6b4085141d4faa4f.png",
                    intro: "我是专业的小红书爆文生产商",
                    name: "爆文创作",
                    status: 0,
                    url: ""
                }
            }
        }
    },
    data() {
        return {
        }
    },
    methods: {
        goTo(item) {
            if (item.status == 1) {
                tempgoTo(item);
            }else if(item.status == 0){
                this.open();
            }
            
        },
        open() {
            this.$prompt('请输入服务商身份码', '身份码', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputPattern: /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/,
                inputErrorMessage: '近期即将上线，尽请期待'
            }).then(({ value }) => {
                this.$message({
                    type: 'success',
                    message: '身份码正确: ' + value
                });
            }).catch(() => {
                // this.$message({
                //     type: 'info',
                //     message: '取消输入'
                // });
            });
        }
    }
}
</script>

<style lang="less">
.top-content{
    // margin:auto;
    cursor: pointer;
    width: 100px;
    height: 130px;
    .top-img{
        width: 100px;
        height: 100px;
        box-sizing: border-box;
        padding: 20px;
        // border: 1px solid #FFCBD3;
        box-shadow: 0px 0px 2px 0px #FFCBD3;
        border-radius: 10px;
        img{
            width: 100%;
            height: 100%;
        }
    }
    .top-img:hover{
        background-color: #F3F4F5;
    }
    .top-text{
        font-size: 16px;
        width: 100%;
        text-align : center;   
        line-height: 40px;
    }
    .el-popover--plain{
        border-radius: 20px;
    }
}

</style>