<template>
  <div>
    <img :src="navimg" class="navimg">
    <div>
      <div style="margin: 30px 0 10px 0">
        <span style="font-weight: 400;font-size: 24px;">Ai文字工具</span>
        <span style="font-size: 15px;color: #666666;margin-left: 15px;">轻松搞定爆款文案</span>
      </div>
      <div class="typeline">
        <!-- 行业类型 -->
        <div v-for="(item, i) in catetypes" :key="i" class="typename" :class="{'typename-active':item.id == oncatetype.id}" @click="ontype(item)">
          <div>{{ item.name }}</div>

          <div v-if="item.id == oncatetype.id" style="    margin-top: 5px; border-radius: 10px; background: rgb(254, 32, 67); height: 5px;"></div>
          <div v-else style=" height: 5px;  margin-top: 5px;"></div>

        </div>
      </div>

      <div class="Titem">
        <CradText v-for="item in datalist" :key="item.id" :item="item" />
      </div>
    </div>
  </div>
</template>

<script>
import {
  // topCate,
  nextCate,
} from '@/api/utils/index';
import CradText from '@/components/CradText';
export default {
  data() {
    return {
      navimg: require('@/assets/Toolsimg/navTextTools.svg'),
      datalist: [],//模块数据列表
      oncatetype: null,//当前行业类型
      catetypes: [
        {
            "id": 6,
            "name": "全部"
        },
        {
            "id": 1,
            "name": "餐饮"
        },
        {
            "id": 2,
            "name": "酒旅"
        },
        {
            "id": 3,
            "name": "休闲娱乐"
        },
        {
            "id": 4,
            "name": "电商"
        },
        {
            "id": 5,
            "name": "医学美容"
        }],//行业类型
    }
  },
  components: {
    CradText
  },
  mounted() {
    this.init();
  },
  methods: {
    ontype(item){
      this.oncatetype = item;
      this.onNextCate();
    },
    async init() {
      // 获取url的数据
      let id = this.$route.query.id;
      if (id) {
        this.oncatetype = { id: id };
      }else{
        this.oncatetype = this.catetypes[0];
      }

      // let catetypes = await topCate();
      // this.catetypes = catetypes.data;
      // console.log(this.oncatetype);
      await this.onNextCate();
    },
    async onNextCate(){
      let list = await nextCate({ id: this.oncatetype.id });
      this.datalist = list.data;
      // console.log(this.datalist, "列表数据");
    },
    /**
     * 调接口获取行业类型
     */


    // 跳转到行业类型
    toIndustryType() {
      this.$router.push('/industryType')
    }
  }
}
</script>

<style lang="less" scoped>
.navimg {
  width: 100%;border-radius: 24px;
}

.typeline {
  display: flex;

  .typename {
    padding: 20px 20px 20px 0;
    cursor: pointer;

    font-size: 18px;
  }
  .typename-active {
    // border-bottom: 2px solid #FE2043;
    color: #FE2043;
  }
}

.Titem {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 30px;
  padding-bottom: 20px;
}
</style>
