<template>
    <div class="sidebar">
        <div class="Smenus">
            <div class="menuitem " v-for="item in menuItems" :key="item.id" @click="handleItemClick(item)" :class="{'menuitem-active':item.id == menuItem}">
               <div class="icon" :class="getClass(item)"></div>
                {{ item.label }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            menuItem: this.$store.state.menuItem,
            menuItems: [
                { id: 1, label: '首页', route: '/index' ,icon:'menu-home',iconactive:'menu-home-active'},
                { id: 2, label: 'AI图片工具', route: '/AIPictureTool',icon:'menu-picture',iconactive:'menu-picture-active' },
                { id: 3, label: 'AI文字工具', route: '/AITextTool',icon:'menu-text',iconactive:'menu-text-active' },
                { id: 4, label: '免费工具', route: '/FreeTools',icon:'menu-free',iconactive:'menu-free-active' },
            ],
        };
    },
    mounted() {
        this.$store.watch(
            (state) => state.menuItem,
            (newValue) => {
                this.menuItem = newValue;
            }
        );
    },
    methods: {
        getClass(item) {
            return item.id != this.menuItem ? item.icon : item.iconactive;
        },
        handleItemClick(item) {
            this.$store.commit('setMenuItems', item.id);
            this.menuItem = item.id;
            this.$router.push(item.route);
            console.log('Clicked:', item);
        },
    },
};
</script>

<style scoped>
.sidebar {
}

.Smenus {
    padding: 28px;
    margin: 0;
    box-sizing: border-box;
    position: fixed;
}

.menuitem {
    width: 100%;
    border-radius: 30px;
    padding: 15px 20px;
    color: #000;
    /* border:1px solid #e0e0e0;
    background: #FE2043; */
    box-sizing: border-box;
    margin-bottom: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: left;
}


.menuitem-active {
    color: #fff;
    /* border:1px solid #e0e0e0; */
    background: #FE2043;
}
</style>
