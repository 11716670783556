<template>
    <div id="container">
    </div>
</template>

<script>
// import { utlisApi } from "./text";
import { utlisApi } from "@/utils/utils";
//在需要使用的页面引入，并实例化对象
import CktDesign from "@chuangkit/chuangkit-design";
export default {
    name: 'AiMatting',
    data() {
        return {
            openid: '',
            option: {},
            utlisApi: new utlisApi()
        }
    },
    mounted() {
        this.init();
        console.log(this.option, "imageEditor");
        var imageEditor = new CktDesign.createMattingDesign(this.option);
        imageEditor.open();
        imageEditor.onSave(this.onSave);
        imageEditor.onClose(this.onClose);
    },
    methods: {
        init() {
            this.utlisApi.openid = "1234";
            this.option = this.utlisApi.getAiMatting({file: "https://test.xhsbds.com/img/20240121/1705818976.png"});
            return this.option;
        },
        /**
         * url转file
         * @param {*} url 
         * @param {*} filename 
         * @param {*} mimeType 
         */
        async urlToFile(url, filename = '未命名', mimeType = 'image/png') {
            const res = await fetch(url);
            const buf = await res.arrayBuffer();
            return new File([buf], filename, { type: mimeType });
        },
        /**
         * 保存事件
         */
        onSave(result) {
            // result为返回的blob文件数据 可自行做操作
            // 以下为下载操作 ----
            let a = document.createElement('a')
            const imageUrl = window.URL.createObjectURL(result.slice(0, result.size, result.type))
            a.setAttribute('href', imageUrl)
            a.setAttribute('download', Date.now() + 'matting.png')
            a.click()
            window.URL.revokeObjectURL(imageUrl)
            // ---- 
            console.log('save...', result)
        },
        /**
         * 取消事件
         */
        onClose(result) {
            // result为返回的数据对象，返回值说明如下
            // 参数名	必选	类型	说明
            // message	是	string	关闭来源  container_close-容器关闭
            console.log('close...', result)
        }
    }
}
</script>

<style>
#imgtext {
    width: 100%;
    height: 100vh;
}
</style>