//dialog.js
import Vue from 'vue';
import Overlay from './newLogin.vue'
let dialogConstructor = Vue.extend(Overlay);


let thsDialog =  ()=> {
    //promise封装，确认执行resolve，取消执行rejectlet
    const dialogDom=new dialogConstructor().$mount()
    dialogDom.open();
    document.body.appendChild(dialogDom.$el); //new一个对象，然后插入body里面
    return 
}



export default thsDialog;