import api from '@/api/';
import axios from 'axios';
import { getFingerprint } from '@/utils/utils.js';
/**
 * 
 * @param {  
 *    var params = {
        username:'test',
        password:'123456',
         level:2
    }} params 
 * @returns 
 */
async function loginto(params = {}) {
    params.device_id = await getFingerprint();
    return await api.post('/api/user/login', params);
}
/**
 * 快速进入首页
 * @param {*} params 
 * @returns 
 */
async function toindex(params = {}) {
    params.device_id = await getFingerprint();
    return await api.post('/api/user/quickLogin', params);
}

/**
 * 菜单
 * @param {} params 
 * @returns 
 */
async function getUserMenu(params = {}) {
    params.device_id = await getFingerprint();
    return await api.post('/api/user/getUserMenu', params);
}

/**
 * 查询首页的模板数据
 */
async function getHomeTemplate(params = {}) {
    params.device_id = await getFingerprint();
    return await api.post('/api/Ai/mixPhoto', params);
}

/**
 * 免费工具
 * @param {} params 
 * @returns 
 */
async function getTools(params = {}) {
    params.device_id = await getFingerprint();
    return await api.post('/api/user/quickLogin', params);
}



/**
 * 查询首页的模板数据行业类型
 */
async function getHometype() {
    return await api.get('/api/Ai/templateCate');
}

/**
 * 根据行业类型查询首页的模板数据
 */
async function getTemplateList(params = {}) {
    params.device_id = await getFingerprint();
    return await api.post('/api/Ai/mainSearch', params);
}
/**
 * 根据ai文字工具查询行业类别
 */
async function topCate() {
    return await api.get('/api/ai/topCate');
}
/**
 * 根据ai文字工具查询模块数据
 */
async function nextCate(params = {}) {
    params.device_id = await getFingerprint();
    return await api.post('/api/ai/nextCate', params);
}

/**
 * 查询具体得赛道和方向
 */
async function cateDetail(params = {}) {
    params.device_id = await getFingerprint();
    return await api.post('/api/ai/textDetail', params);
}


/**
 * ai文字对话
 */
async function aiChat(params = {}) {
    params.device_id = await getFingerprint();
    // 创建一个catgpt实例process.env.VUE_APP_Server
    return await fetch(window.appConfig.baseUrl[process.env.NODE_ENV] + '/api/info/show1', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            // 'Authorization': `Bearer ${apiKey}`
            // 'Content-Type': 'text/event-stream',
            'Cache-Control': 'no-cache',
            'Connection': 'keep-alive'
        },
        body: JSON.stringify(params)
    }).then(response => {
        // 修改返回的Content-Type为'text/event-stream'
        const updatedHeaders = new Headers(response.headers);
        updatedHeaders.set('Content-Type', 'text/event-stream');
        updatedHeaders.set('Cache-Control', 'no-cache');
        updatedHeaders.set('Connection', 'keep-alive');
        const updatedResponse = new Response(response.body, {
            status: response.status,
            statusText: response.statusText,
            headers: updatedHeaders
        });
        return updatedResponse;
    });
}
/**
 * 上传图片
 * process.env.VUE_APP_Server
 */
async function imgupload(formData) {
    return await axios.post(window.appConfig.baseUrl[process.env.NODE_ENV] + '/api/common/upload', formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}
/**
 * 调用图文去重添加任务接口
 */
async function toDuplication(params = {}) {
    params.device_id = await getFingerprint();
    return await api.post('/api/baidu/dealBig', params);
}
/**
 * 调用图文去重查询任务进度接口
 */
async function tolookRes(params = {}) {
    params.device_id = await getFingerprint();
    return await api.post('/api/baidu/lookRes', params);
}


/**
 解析
 */
async function parsing(params   = {}){
    params.device_id = await getFingerprint();
    return await api.post('/api/Ai/dealMix',params);
}




export {
    loginto,
    getUserMenu,
    getTools,
    getHomeTemplate,
    getHometype,
    getTemplateList,
    topCate,
    nextCate,
    cateDetail,
    aiChat,
    imgupload,
    toDuplication,
    tolookRes,
    parsing,
    toindex
}