import Login from "@/views/Login";
import Home from "@/views/Home";
import OldHome from "@/views/OldHome";
import DesignEditor from "@/views/ckt/DesignEditor";//设计编辑器
import DesignCenter from "@/views/ckt/DesignCenter";//设计中心
import ImageEditing from "@/views/ckt/ImageEditing";//图片编辑器
import AiPainter from "@/views/ckt/AiPainter";//ai画匠
import pictureModify from "@/views/ckt/pictureModify";//智能扩图
import pictureDrag from "@/views/ckt/pictureDrag";//智能改图
import AiMatting from "@/views/ckt/AiMatting";//智能抠图
import AiDesign from "@/views/ckt/AiDesign";//ai智能设计
import FreeTools from "@/views/FreeTools";//免费工具
import AITextTool from "@/views/AITextTool";//ai文字工具
import AIPictureTool from "@/views/AIPictureTool";//ai图文工具

import xhs from "@/views/PictureTools/xhs";//展示小红书模板
import pictrueMod from "@/views/PictureTools/pictrueMod";//展示ai扩图模板
import pictureUp from "@/views/PictureTools/pictureUp";//展示ai修图模板
import pCenter from "@/views/PictureTools/pCenter";//展示模板中心
import pclear from "@/views/PictureTools/pclear";//展示变清晰
import pcommodity from "@/views/PictureTools/pcommodity";//展示商品图
import pImageRemoval from "@/views/PictureTools/pImageRemoval";//展示图文去重
import pRwatermark from "@/views/PictureTools/pRwatermark";//展q去s水印
// import newAppls from "@/views/newAppls/index.vue";//服务商申请


import TextResults from "@/views/TextTools/TextResults";//ai图文工具(结果)
import TextEditor from "@/views/TextTools/TextEditor";//ai图文工具（编辑）


import NewHome from "@/views/NewHome";
// import text from "@/views/NewHome/text";
import template from "@/views/NewHome/template";

// import layout from "@/views/layout";


// import Blog from "@/views/Blog";
// import Project from "@/views/Project";
// import Message from "@/views/Message";

export default [
    {
      name: "Login", path: "/login",
      component: Login
    },
    {
        name: "Home", path: "/Home", component: Home,
        meta: {requiresAuth: true} // 需要登录才能访问的页面
    },
    {
        name: "NewHome", path: "/", component: template,
        // 子模块
        children: [
            {
                path: 'index',
                component: NewHome
            },
            {
                //图文工具
                path: 'AIPictureTool',
                component: AIPictureTool
            },
            {
                //文字工具
                path: 'AITextTool',
                component: AITextTool
            },

            {
                //免费工具
                path: 'FreeTools',
                component: FreeTools
            },
            {path: "TextResults", component: TextResults},//ai图文工具(结果)
            {path: "TextEditor", component: TextEditor},//ai图文工具（编辑）
            {path: "xhs", component: xhs},//展示小红书模板
            {path: "pictrueMod", component: pictrueMod},//展示ai扩图模板
            {path: "pictureUp", component: pictureUp},//展示ai修图模板
            {path: "pCenter", component: pCenter},//展示ai修图模板
            {path: "pclear", component: pclear},//展示变清晰
            {path: "pcommodity", component: pcommodity},//展示商品图
            {path: "pImageRemoval", component: pImageRemoval},//展示图片去重
            {path: "pRwatermark", component: pRwatermark},//展q去s水印
            // {  path: "/newAppls", component: newAppls },//服务商申请
        ]
    },
    {name: "OldHome", path: "/OldHome", component: OldHome},
    {name: "DesignEditor", path: "/DesignEditor", component: DesignEditor},
    {name: "DesignCenter", path: "/DesignCenter", component: DesignCenter},
    {name: "ImageEditing", path: "/ImageEditing", component: ImageEditing},
    {name: "AiPainter", path: "/AiPainter", component: AiPainter},
    {name: "pictureModify", path: "/pictureModify", component: pictureModify},
    {name: "pictureDrag", path: "/pictureDrag", component: pictureDrag},
    {name: "AiMatting", path: "/AiMatting", component: AiMatting},
    {name: "AiDesign", path: "/AiDesign", component: AiDesign},

    // { name: "Blog", path: "/article", component: Blog },
    // { name: "Project", path: "/project", component: Project },
    // { name: "Message", path: "/message", component: Message },
    // 404 页面
    // {
    //   path: "/404",
    //   name: "NotFound",
    //   component: () => import("@views/404.vue"),
    //   hidden: true,
    //   meta: { title: "404" },
    // },
    // 当什么都没有匹配到的时候，重定向页面到 404 页面
    // {path: "/:pathMatch(.*)", redirect: "/index", name: "notMatch", hidden: true},
];
