<template>
    <div class="user-info">
        <el-dropdown @command="handleCommand">
            <span class="el-dropdown-link user-info">
                <!-- todo: 头像 -->
                <img src="@/assets/avatar.png" class="avatar" />
                <span class="nick">{{ userInfo?.nickname || name }}</span>
            </span>
            <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-if="isEmpty(userInfoFromStore)" command="someMethod">登录账户</el-dropdown-item>

                <el-dropdown-item v-if="!isEmpty(userInfoFromStore)" command="opendialog">设备管理</el-dropdown-item>
                <el-dropdown-item v-if="!isEmpty(userInfoFromStore)" command="LogOut">退出登录</el-dropdown-item>
                <el-dropdown-item v-if="isEmpty(userInfoFromStore)" command="outlogin">返回入口</el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>

    </div>
</template>

<script>
import { Message } from 'element-ui';
export default {
    props: {
        user: {
            type: Object,
            default: function () {
                return {
                    avatar: "https://top.xhsbds.com/assets/img/avatar.png",
                    // avatar: "/assets/img/avatar.png",
                    nickname: "测试账号"
                }
            }
        }
    },
    data() {
        return {
            img: 'https://top.xhsbds.com/assets/img/avatar.png',
            userInfo: {},
        }
    },
    computed: {
        src() {
            // return process.env.VUE_APP_Server + this.user.avatar;
            return window.appConfig.baseUrl[process.env.NODE_ENV] + this.user.avatar;
        },
        name() {
            return "Hi," + this.user.nickname
        },
        // username1() {
        //     console.log("输出vuex中的内容",this.$store.state.userinfo?.nickname);
        //     return this.$store.state.userinfo?.nickname
        // },
        // 计算属性 userInfoFromStore 监听 this.$store.state.userInfo 的变化
        userInfoFromStore() {
            return this.$store.state.userInfo;
        },
    },
    watch: {
        // 监听计算属性 userInfoFromStore 的变化，当其变化时更新 userInfo 的值
        userInfoFromStore: {
            deep: true,
            immediate: true,
            handler(newValue) {
                this.userInfo = newValue;
            },
        },
    },
    mounted() {
        this.img = this.src;
        //判断window.localStorage是否有userinfo
        if (window.localStorage.getItem("userInfo")) {
            this.userInfo = JSON.parse(window.localStorage.getItem("userInfo"));
        }
    },
    methods: {
        opendialogVisible() {
            // console.log("opendialogVisible");
            //修改vuex中设备状态的值
            this.$store.commit('setshouldOpenDeviceManage', true);
        },
        isEmpty(obj) {
            if (localStorage.getItem("userInfo")) {
                return false;
            }
            return Object.keys(obj).length === 0;
        },
        changeImg() {
            this.img = this.src
        },
        //登录账户
        someMethod() {
            //调用登录vuex
            this.$store.dispatch('openLogin');
        },
        //退出登录
        async LogOut() {
            console.log("LogOut");
            this.$message({
                message: "退出登录完成",
                type: "success",
                duration: 3 * 1000,
            })
            this.$store.commit('setUserInfo', {});
            window.localStorage.removeItem("userInfo");
            window.localStorage.removeItem("token");
            window.localStorage.removeItem("user");
            this.userInfo = {};
            this.$router.push("/");
            window.location.reload();
        },
        // 返回首页
        outlogin() {
            console.log("outlogin");
            Message({
                message: "退出登录完成",
                type: "success",
                duration: 3 * 1000,
            })
            // this.$Gconfig.token = null;
            // this.$Gconfig.user = null;

            window.localStorage.removeItem("token");
            this.$router.push("/");
            window.location.reload();
        },
        handleCommand(command) {
            console.log('click on item ' + command);
            switch (command) {
                case 'outlogin':
                    this.outlogin();
                    break;
                case 'someMethod':
                    this.someMethod();
                    break;
                case 'LogOut':
                    this.LogOut();
                    break;
                case 'opendialog':
                    this.opendialogVisible();
                    break;
                default:
                    break;
            }
        }
    }
}
</script>

<style lang="less" scoped>
.user-info {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.avatar {
    width: 46px;
    height: 46px;
    border-radius: 50%;
    padding: 10px;
}
</style>
