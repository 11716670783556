<template>
  <div class="card-data" :style="dynamicStyle"  @mouseenter="changeBackground" @mouseleave="resetBackground" @click="tolink">

    <div class="card-data-item" >
      <div class="card-data-logo">
        <transition name="fade">
            <img class="card-data-logo" :src="data.logo" alt="" v-if="ismouse" >
        </transition>
      </div>

      <div class="card-data-title" :style="{ transform: `translateX(${translate}px)` }">
        <div class="card-data-title-name" :style="{color:fontColor}">{{data.title}}</div>
        <div class="card-data-title-notes" :style="{color:fontColor}">
          <span class="pellet" :style="{background:strpellet}"></span>
          {{data.notes}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'CardContent',
    props: ['data'],
    data() {
        return {
            // 卡片内容
            content:{
              title:'小红书酒旅入驻',
              logo:'/image/buying.png',
              notes:'酒店旅行，让旅行不一般',
              img:'/image/buying-img.png',
              link:'http://www.baidu.com',
              color:'#F3F2FB',
              bgcolor:"#fff",
              ocolor:'#000',
            },
            fontColor:'#000',
            translate: 0,
        }
    },
    mounted() {
      this.content.bgcolor = this.getColor()
        // this.content = this.item;
    },
    computed: {
        dynamicStyle() {
            return {
                background:this.content.bgcolor,
                backgroundSize: 'cover'
            }
        },
        /**
         * 移除为true，移入为false
         */
        ismouse(){
          if(this.fontColor !='#fff'){
            return true;
          }else{
            return false;
          }
        },
        strpellet(){
          if(this.ismouse){
            return `linear-gradient(to right,${this.data.ocolor}, rgba(255,255,255,0)`;
          }else{
            return `linear-gradient(to #000, #fff)`;
          }
        } 
    },
    methods: {
      changeBackground() {
        this.content.bgcolor = `url(${this.data.img}) no-repeat`; // 鼠标移入时更改背景图片路径
        this.fontColor ='#fff';
        this.translate = -80;
      },
      resetBackground() {
        this.content.bgcolor = this.getColor(); // 鼠标移出时恢复默认背景图片路径
        this.fontColor ='#000';
        this.translate = 0;
      },
      // 根据颜色拼接color值
      getColor() {
        return `linear-gradient(to right,${this.data.color} 50%, rgba(255,255,255,0))`;
      },
      tolink(){
        window.open(this.data.link,"_blank");
      }
    }
}
</script>

<style scoped>
.card-data {
    padding: 10px;
    font-size: 14px;
    color: #666;
    line-height: 22px;
    width: 30vw;
    height: 150px;
    overflow: auto;
    display: flex;
    border-radius: 10px;
    transform:all 3s  ease-in-out;
    background-position: 1/3;
    background-size: cover; 
    background-repeat: no-repeat; 
    background-position: center center; 
}
.card-data-item{
    display: flex;
    justify-self: start;
    align-items: center;
}
.card-data-logo{
  margin-left: 4px;
  padding: 5px;
  width: 72px;
  height: 82px;
  margin-right: 10px;
}
.card-data-title-name{
  font-size: 24px;
  font-family: 'Source Han Sans, Source Han Sans';
  font-weight: 500;
  color: #1A1A1A;
  line-height: 35px;
}

/* 画个小球 */
.pellet{
  border-radius: 50%;
  width: 10px;
  height: 10px;
  display: inline-block;
  animation: pellet 1s infinite alternate;;
}


.fade-enter-active,
.fade-leave-active {
  transition: all 1s;
}
.card-data-title{
  
  margin-left: 8px;
  transition: transform 1s;
}
.fade-enter, .fade-leave-to {
  /* opacity: 0; */
  transform: translateX(-100px);
}

</style>