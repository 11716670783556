
import { Message } from 'element-ui';

import Fingerprint2 from 'fingerprintjs2';

import md5 from 'js-md5'
import * as newapi from '@/api/utils/newindex.js'

function tempgoTo(item) {
    console.log(item, "item")
    if (item.status != 0) {
        window.open(item.url)
    } else {
        // this.dialogVisible = true
        Message({
            message: '权限未开放',
            type: "info",
            duration: 3 * 1000,
        })
    }
}

function open() {
    this.$prompt('请输入供应商授权码', '授权码', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/,
        inputErrorMessage: '授权码有误'
    }).then(({ value }) => {
        this.$message({
            type: 'success',
            message: '授权码正确: ' + value
        });
    }).catch(() => {
        this.$message({
            type: 'info',
            message: '取消输入'
        });
    });
}
class utlisApi {
    constructor() {
        // 用户唯一标识
        this.openid = 0;
        this.timestamp = new Date().getTime();
        this.app_id = 'fbadd5065b7b446eac5fb52dd3381365';
        this.app_skey = '88F4A30966AED3A8352EE38DE6034F34';
        //自定义参数（各个模块会不同）
        this.myparams = {}
    }
    setopenid(openid) {
        console.log(openid);
        this.openid = openid
    }
    /**
     * 根据验签规则生成签名
     * @param {*} params 
     * @returns 
     */
    getSign(params) {
        let pArr = []
        for (let key in params) {
            pArr.push(`${key}=${params[key]}`)
        }
        console.log(pArr.sort().join('&'), "打印一下签名")
        return md5(pArr.sort().join('&')).toUpperCase()
    }
    /**
     * 验签函数后的参数
     */
    getSignkey() {
        // 其他模块的验签参数
        let defaultParams = {
            app_id: this.app_id, // 企业appID
            timestamp: this.timestamp , // 过期时间
            union_id: this.openid, // 用户唯一标识
            app_secret: this.app_skey,
        }
        return this.getSign(defaultParams)
    }
    /**
     * 模板编辑器的验签参数
     */
    getEditorSignkey() {

        // 下面是模板编辑器验签参数
        const EditorParams = {
            app_id: this.app_id,
            app_secret: this.app_skey,
            expire_time: this.timestamp + 1000 * 60,
            user_flag: this.openid,
        }
        return this.getSign(EditorParams)

    }
    /**
     * 模板中心的验签参数
     */
    getCenterSignkey(params) {
        const CenterParams = {
            app_id: this.app_id, // 企业appID
            timestamp: this.timestamp , // 过期时间
            app_secret: this.app_skey,
            setting_code: params.settingCode || "NLA7",
        }
        return this.getSign(CenterParams);
    }
    /**
     * 返回最终验签的参数
     * @param {*} myparams 
     * @returns 
     */
    getParams() {
        if (this.myparams == {}) {
            throw new Error("请传入该模块自定义参数")
        }
        return {
            ...this.myparams,
            sign: this.getSignkey()
        }
    }

    //下面记录下各个模块的参数
    /**
     * ai智能设计
     * @param {*} myparms 
     * @returns 
     */
    getAiDesign() {
        this.timestamp = new Date().getTime() + 1000 * 60 *5;
        const mParams = {
            appId: this.app_id, // 企业appID
            timestamp: this.timestamp, // 过期时间
            unionId: this.openid, // 用户唯一标识
        }
        this.myparams = Object.assign(mParams);
        return this.getParams();
    }
    /**
     * 智能抠图
     * @param {*} myparms 
     * @returns 
     */
    getAiMatting(params) {
        if ('file' in params) {
            console.log('params 包含 file');
        } else {
            throw new Error("请传入{file:url}智能抠图的文件，可以是url，也可以是file对象")
        }
        this.timestamp = new Date().getTime() + 1000 * 60 *5;
        const mParams = {
            appId: this.app_id, // 企业appID
            timestamp: this.timestamp, // 过期时间
            unionId: this.openid, // 用户唯一标识
            container: "#container", //容器
            hideLogo: true,//是否隐藏创客贴logo，默认是展示logo的
        }
        this.myparams = Object.assign(mParams, params)
        return this.getParams();
    }
    /**
     * 智能改图
     * @param {*} myparms 
     * @returns 
     */
    getpictureDrag() {
        // 验签时间大于当前时间5分钟
        this.timestamp = new Date().getTime() + 1000 * 60 *5;
        const mParams = {
            appId: this.app_id, // 企业appID
            timestamp: this.timestamp, // 过期时间
            unionId: this.openid, // 用户唯一标识
            container: "#container",
            device_type: 1,
            companyFlag: "testinpainting",
        }
        
        this.myparams = Object.assign(mParams);
        return this.getParams();
    }

    /**
     * 智能扩图
     * @param {*} myparms 
     * @returns 
     */
    getpictureModify() {
        
        // 验签时间大于当前时间5分钟
        this.timestamp = new Date().getTime() + 1000 * 60 *5;
        const mParams = {
            appId: this.app_id, // 企业appID
            timestamp: this.timestamp, // 过期时间
            unionId: this.openid, // 用户唯一标识
            container: "#container",
            device_type: 1,
            companyFlag: "testoutpainting",
        }
        this.myparams = Object.assign(mParams);
        return this.getParams();
    }
    /**
     * ai画匠
     * @param {*} params 
     * @returns 
     */
    getAiPainter(params = {}) {
        // 验签时间大于当前时间5分钟
        this.timestamp = new Date().getTime() + 1000 * 60 *5;
        if ('mParam' in params) {
            console.log('params 包含 mParam');
        } else {
            throw new Error("请传入{mParam:namber}默认打开的路径，可以是6：变清晰，5：商品图")
        }
        const mParams = {
            appId: this.app_id, // 企业appID
            timestamp: this.timestamp , // 过期时间
            unionId: this.openid, // 用户唯一标识
            container: "#container",
            // mParam: 5,
            device_type: 1,//设备类型 1:pc 2:移动端
            companyFlag: "bdstesteditor",
        }
        this.myparams = Object.assign(mParams, params);
        console.log("获取临时数组",this.myparams);
        return this.getParams();
    }
    /**
     * 图片编辑器
     * @param {*} myparms 
     * @returns 
     */
    getImageEditing() {
        // 每次鉴权都获取最新的时间戳
        this.timestamp = new Date().getTime();
        const mParams = {
            appId: this.app_id, // 企业appID
            timestamp: this.timestamp, // 过期时间
            container: "#container",
            unionId: this.openid, // 用户唯一标识
            hideLogo: true,//是否隐藏创客贴logo，默认是展示logo的
            closeType: 1,//完成后页面是否关闭弹窗 0-关闭 1-不关闭 
        }
        this.myparams = mParams;
        return this.getParams(mParams);
    }
    /**
     * 模板设计中心 TODO: 模板中心的鉴权不一样
     * @param {*} myparms 
     * @returns 
     */
    getDesignCenter(params) {
        this.timestamp = new Date().getTime()-1000;
        if ('settingCode' in params) {
            console.log('params 包含 settingCode');
        } else {
            throw new Error("请传入{settingCode:String}模板中心的参数")
        }
        const mParams = {
            appId: this.app_id, // 企业appID
            timestamp: this.timestamp, // 过期时间
            container: "#container",
            settingCode:params.settingCode || "NLA7",
            userflag: this.openid, // 用户唯一标识
        }
        return {
            ...mParams,
            sign: this.getCenterSignkey(params)
        };
    }
    /**
     * 模板编辑器 TODO: 模板编辑器的鉴权不一样调用的是getEditorSignkey方法
     */
    getDesignEditor(params = {}) {
        if (("template_id" in params) || ("design_id" in params) || ("kind_id" in params) || ("random_id" in params)) {
            console.log("模板编辑器的验签参数")
        } else {
            throw new Error("请传入{template_id:namber,design_id:namber,kind_id:namber,random_id:namber}模板编辑器的参数");
        }
        return {
            ...params,
            app_id: this.app_id,
            expire_time: this.timestamp + 1000 * 60,
            user_flag: this.openid,
            device_type: 1,//1pc端、2移动端
            version: '2.0',
            sign: this.getEditorSignkey()
        }
    }

    /**
     * url转file
     * @param {*} url 
     * @param {*} filename 
     * @param {*} mimeType 
     */
    async urlToFile(url, filename = '未命名', mimeType = 'image/png') {
        const res = await fetch(url);
        const buf = await res.arrayBuffer();
        return new File([buf], filename, { type: mimeType });
    }

    /**
     * 智能设计高级编辑
     * @param {*} myparms 
     * @returns 
     */
    getImageDesignByDesignId(params={}) {
        if ('designId' in params) {
            console.log('params 包含 designId');
        } else {
            throw new Error("请传入{designId:String}模板中心的参数")
        }

        // 每次鉴权都获取最新的时间戳
        this.timestamp = new Date().getTime();
        const mParams = {
            appId: this.app_id, // 企业appID
            timestamp: this.timestamp, // 过期时间
            container: "#container",
            unionId: this.openid, // 用户唯一标识
            hideLogo: true,//是否隐藏创客贴logo，默认是展示logo的
            closeType: 1,//完成后页面是否关闭弹窗 0-关闭 1-不关闭 
            designId:params.designId,
        }
        this.myparams = mParams;
        return this.getParams(mParams);
    }
}

// 获取设备指纹
const getFingerprint = async () => {
    // var that = this;
    var temp = await new Promise((resolve) => {
        Fingerprint2.get(function (components) {

            // console.log("data设备参数",components);

            //以下设备信息不参与生成id
            let errvalue = ['components', 'plugins', 'doNotTrack', 'addBehavior', 'indexedDb', 'fonts', 'fontsFlash', 'enumerateDevices'];

            const values = components.map(function (component, index) {
                if (index === 0) { //把微信浏览器里UA的wifi或4G等网络替换成空,不然切换网络会ID不一样
                    return component.value.replace(/\bNetType\/\w+\b/, '')
                }
                if (errvalue.indexOf(component.key) > -1) {
                    return ''
                }
                return component.value
            })
            // 生成最终id murmur   
            const murmur = Fingerprint2.x64hash128(values.join(''), 31)
            resolve(murmur);
        })
    });

    return temp;
}

/**
 * 查询设备基本信息
 * @returns 
 */
async function JudgmentEnvironment(){
    var userAgentStr = window.navigator.userAgent;
    let string = ''
    let stringtwo = ''
    if(/Mobile/.test(userAgentStr)){
		string='phone';
	}else{
		string='pc';
	}
	if (/MicroMessenger/.test(userAgentStr)) {
		stringtwo='WeChat(微信)'
	}else if(/AlipayClient/.test(userAgentStr)) {
		stringtwo='Alipay(支付宝)'
	}else if(/QQTheme/.test(userAgentStr)) {
		stringtwo='QQ'
	}else if(/HeyTapBrowser/.test(userAgentStr)) {
		stringtwo='HeyTapBrowser(HeyTap)'
	}else if(/VivoBrowser/.test(userAgentStr)) {
		stringtwo='VivoBrowser(vivo)'
	}else if(/MQQBrowser/.test(userAgentStr)) {
		stringtwo='MQQBrowser(QQ浏览器移动版)'
	}else if(/QQBrowser/.test(userAgentStr)) {
		stringtwo='QQBrowser(QQ浏览器)'
	}else if(/baiduboxapp/.test(userAgentStr)) {
		stringtwo='baiduboxapp(百度app)'
	}else if(/Weibo/.test(userAgentStr)) {
		stringtwo='Weibo(新浪微博)'
	}else if(/iqiyi/.test(userAgentStr)) {
		stringtwo='iqiyi(爱奇艺)'
	}else if(/aweme/.test(userAgentStr)) {
		stringtwo='aweme(抖音)'
	}else if(/kdtUnion_iting/.test(userAgentStr)) {
		stringtwo='kdtUnion_iting(喜马拉雅)'
	}else if(/DingTalk/.test(userAgentStr)) {
		stringtwo='DingTalk(钉钉)'
	}else if(/UCBrowser/.test(userAgentStr)) {
		stringtwo='UCBrowser(UC浏览器)'
	}else if(/SamsungBrowser/.test(userAgentStr)) {
		stringtwo='SamsungBrowser(三星浏览器)'
	}else if(/HUAWEIEVA/.test(userAgentStr)) {
		stringtwo='huaweiBrowser(华为浏览器)'
	}else if(/NokiaBrowser/.test(userAgentStr)) {
		stringtwo='NokiaBrowser(诺基亚浏览器)'
	}else if(/Opera/.test(userAgentStr)) {
		stringtwo='Opera(欧朋)'
	}else if(/Firefox/.test(userAgentStr)) {
		stringtwo='Firefox(火狐)'
	}else if(/Chrome/.test(userAgentStr)) {
		stringtwo='Chrome(谷歌)'
	}else if(/Safari/.test(userAgentStr)) {
		stringtwo='Safari(苹果)'
	}else{
		stringtwo='other'
	}
 
   return JSON.stringify( {
        deviceId:null,
        deviceType:string,
        deviceModel: stringtwo,
        user_agent : window.navigator.userAgent,
        language : window.navigator.language,
        platform : window.navigator.platform,
        appVersion : window.navigator.appVersion
   });
}




//根据token查询是否有ai工具权限
async function getaiCanUse() {
    let res = await newapi.getUserInfo({});
    console.log("res接口",res);
    //先判断是否登录
    // if(res.code == 401){
    //     return true;
    // }
    //先判断localstorage是否有token
    if (!localStorage.getItem('userInfo')) {
        return false;
    }


    if (res.code === 200) {
        // this.$store.commit('setUserInfo', res.data);
        if(res.data.ai_can_use == 1){
            //可以使用ai工具
            return true;
        }else{
            return false;
        }

    } else {
        this.$message.error(res.msg);
        return false;
    }
}
//判断是否有权限使用ai工具
async function showAiCanUse(){
    //判断是否有权限
    let isUse = await this.$utils.getaiCanUse();
    return Promise((resolve,reject)=>{
        if(isUse){
            resolve()
        }else{
            Message({
                message: '您没有权限使用AI功能，请检查是否登录/小程序开通套餐',
                type: "error",
                duration: 3 * 1000,
            })
            // this.$message.error('您没有权限使用AI功能，请检查是否登录/小程序开通套餐');
            reject("没有权限")
        }
    })
}

export {
    tempgoTo, open, utlisApi,getFingerprint,JudgmentEnvironment,getaiCanUse,showAiCanUse
}

