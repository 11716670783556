
import md5 from "js-md5"
// import './EncoderDecoderTogether.min.js'



class GPTMsg {
	constructor() {
		//api请求地址
		this.url = "https://top.xhsbds.com/api/info/show"
		// this.url = "http://192.168.1.17:8000/index/show"
		// 获取的消息数据
		this.msgs = "";
		// key值
		this.key = "muyouyu";
		this.params = "codeLiu";
		this.sign_secret = "BDS";
		//这个是组装对话；
		this.msgDialogue = [];
		this.roles = ["user","assistant"];
		this.chat_id= null;
	}
	getKey(){
		return this.key
	
	}
	/**
	 * 获取最新的md5令牌
	 * @returns 
	 */
	getSign() {
		const str = this.key + this.params + this.sign_secret;

		// 使用 md5 字符串
		return md5(str);
	}
	/**
	 * 获取当前时间戳
	 */
	getTimestamp() {
		// var timestamp = new Date().getTime();
		return parseInt(new Date().getTime() / 1000);
	}
	/**
	 * 将二进制文件，转utf8字符串文本
	 * 并将字符串拆分为数组,并返回当前是否是最后一行
	 */
	sliceMsg(text) {
		// 二进制转utf-8文本
		// let decoder = new TextDecoder('utf-8');
		// let text = decoder.decode(new Uint8Array(res.data));
		console.log("打印一下文本",text);
		/***/
		let isEnd = false;
		if (text.includes('data: [DONE]')) {
			isEnd = true;
			//移除最后一个标记
			text = text.replace('data: [DONE]', '');
		}
		// 按行分割，并将data中的数据提取出来
		let lines = text.split('\n').filter(line => line.startsWith('data: '));
		let objects = lines.map(line => JSON.parse(line.slice(6)));
		let contents = objects.map(obj => obj.choices[0]?.delta?.content).filter(content => content !== undefined).join('');



		
		// let chat_id = objects.map(obj => obj?.id).filter(id => id !== undefined).join('');
		// console.log(objects);
		// 将结果返回
		return {
			isEnd,
			contents,
			// chat_id
		};
	}

	/**
	 * 传入msg片段，拼接到
	 * @param {*} msg 
	 */
	addMsg(msg) {
		this.msgs += msg;
	}
	end() {
		//设置结束标志
		this.stopAction = true
	}
	/**
	 * 组装对话
	 * 将用户选择的模块和内容拼接在一起传入服务器
	 */
	setMsg(role,content){
		//这个是组装对话模板；
		const msgType = {
			"role": role,
			"content": content
		}
		this.msgDialogue.push(msgType);
	}
	getMsg(){
		return this.msgDialogue;
	}
	/**
	 * 统计对话次数
	 */
	getMsgCount(){
		return this.msgDialogue.length;
	}
	/**
	 * 返回拼接完成后的语句
	 * @param {*} temp 传入的关键词
	 * const temp = {text: this.text,type: this.setItem,type2: this.setItem2}
	 * @param {*} text 用户输入的文字
	 * @returns 
	 */

	contentText(temp){
		const str = `请使用 Emoji 风格编辑以下段落，该风格以引人入胜的标题、每个段落中包含表情符号和在末尾添加相关标签为特点。从以下${temp.type}方向和${temp.type2}相关类型中回答,请确保保持原文的意思：${temp.text}`
		return str;
	}

}

export default GPTMsg;