import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // 这里是你的应用程序的状态
    menuItem:1,//侧边栏当前选中的菜单
    openid:'1234',//用户的openid
    shouldOpenLogin: false,//是否打开登录弹窗
    userInfo: {},
    loginRole: null,//登录角色判断是服务商还是普通商家
    
    shouldOpenDeviceManage: false,//是否打开设备管理的弹窗
  },
  mutations: {
    // 这里是修改状态的方法
    // 修改侧边栏选中的菜单
    setMenuItems(state,menu){
      state.menuItem = menu;
    },
    setOpenid(state,openid){
      state.openid = openid;
    },    
    openLogin(state) {
      state.shouldOpenLogin = true;
    },
    closeLogin(state) {
      state.shouldOpenLogin = false;
    },

    setUserInfo(state,userInfo){
      state.userInfo = userInfo;
    },
    setLoginRole(state, role) {
      state.loginRole = role;
    },
    setshouldOpenDeviceManage(state,shouldOpenDeviceManage){
      state.shouldOpenDeviceManage = shouldOpenDeviceManage;
    }
  },
  actions: {
    // 这里是触发 mutations 的方法    
    openLogin({ commit }) {
      commit('openLogin');
    },
    closeLogin({ commit }) {
      commit('closeLogin');
    },
  },
  getters: {
    // 这里是获取状态的方法
  }
})