<template>
    <div>
        <div class="content">
            <div class="ptype">
                <div style="">行业:</div>
                <!-- 单选 -->
                <div v-for="item in profession" :key="item.kindId" class="ptyperadio"
                    :class="{ 'ptyperadio-active': item.kindId == pfitem }" @click="onsetptye(item)">
                    {{ item.name }}
                </div>
            </div>
            <Stick :list="listdata" @onScrollEnd="loadMore">
                <template slot-scope="scope">
                    <div class="card" style="position: relative;">
                        <img :src="scope.data.cutDownTemplateImageUrl" class="scopeimg" />
                        <div class="scopemask">
                            <!-- 添加点击事件，点击打开tab查看图片 -->
                            <div class="scopebtn" style="color: #fff;"
                                @click="openImage(scope.data.cutDownTemplateImageUrl)">查看原图</div>
                            <div class="scopebtn" style="color: #fff;" @click="onUpEditor(scope.data)">使用模板</div>
                        </div>
                    </div>
                </template>
            </Stick>
        </div>
        <el-dialog
        title="模板预览"
        :visible.sync="dialogVisible"
        width="30%">
        <!-- <span>这是一段信息{{ template_img }}</span> -->
        <img :src="template_img" style="width: 100%;" />
        </el-dialog>
    </div>
</template>

<script>
import { utlisApi } from "@/utils/utils";
//在需要使用的页面引入，并实例化对象
import CktDesign from "@chuangkit/chuangkit-design";

import { getHometype, getTemplateList } from '@/api/utils/index.js';
import Stick from 'vue-stick';


window.chuangkitComplete = function(data){
    console.log(data[`thumb-urls`][0],"下载文件");
    window.open(data[`thumb-urls`][0], '_blank');
}
export default {
    name: 'IndexTemplate',
    components: {
        Stick: Stick.component
    },
    props: {
    },
    data() {
        return {
            profession: [
                { kindId: 1, name: '推荐' },
                { kindId: 2, name: '美食' },
                { kindId: 3, name: '酒饮' },
                { kindId: 4, name: '酒店' },
                { kindId: 5, name: '旅行' },
                { kindId: 6, name: '休闲娱乐' },
                { kindId: 7, name: '电商' },
                { kindId: 8, name: '医学美容' },
                { kindId: 9, name: '更多' }
            ],
            pfitem: 1,
            listdata: [],
            utlisApi: new utlisApi(),
            dialogVisible: false,
            template_img: '',
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            // 获取行业类型
            const res = await getHometype();
            console.log(res, "行业类型");
            this.profession = res?.data;
            this.pfitem = res?.data[0].kindId;
            //获取根据行业类型模板列表
            const data = await getTemplateList({ kindIds: this.pfitem });
            this.listdata = data?.data;
            console.log(this.listdata, "模板列表");
        },
        loadMore: function () {
            // this.list.push({
            //     "cover": "http://static.bh-lay.com/...yout.png",
            //     "intro": "今天的分享主要围绕 可视化布局模块的一些...",
            //     "title": "added by scroll 可视化布局模块开发分享"
            // })
            // 滑倒底部时，加载更多执行该方法
        },
        // 查看原图
        openImage(url) {
            // window.open(url, '_blank');
            this.dialogVisible = true;
            this.template_img = url;
        },
        /**
         * 点击行业类型，则调接口刷新页面
         * @param {} str 
         */
        async onsetptye(str) {
            console.log(this.pfitem, str,"行业类型");
            if(str.name == '其他' || str.name == '更多'){
                this.$router.push("/pCenter");
            }
            this.pfitem = str.kindId;
            //获取根据行业类型模板列表
            const data = await getTemplateList({ kindIds: this.pfitem });
            this.listdata = data?.data;
        },
        /**
         * 点击使用模板，打开编辑器
         */
        async onUpEditor(item) {

            let isUse = await this.$utils.getaiCanUse();
            if(!isUse){
                this.$message.error('您没有权限使用AI功能，请联系管理员开通权限');
                return ;
            }
           let userinfo = window.localStorage.getItem('userInfo');
            userinfo = JSON.parse(userinfo);
            console.info(userinfo,isUse,"打印信息");
            // console.log(item, "点击使用模板，打开编辑器");
            this.utlisApi.openid = userinfo.id;
            // 点击拉起
            const myparams = {
                template_id: item?.designTemplateId ||'365561', // 模板id
            }
            this.option = this.utlisApi.getDesignEditor(myparams);
            console.log(this.option, "打印一下myparams");
            this.design = new CktDesign(this.option);
            this.design.open();
         }
    }
}
</script>

<style lang="less" scoped>
.classTool {
    margin: 20px 0 10px 0;
    // color: #FE2043;
    display: flex;
    flex-direction: row;

    .tools {
        margin: 20px 0 10px 0;
        font-size: 20px;
        line-height: 29px;
        text-align: center;
        // border-bottom: 10px solid #FE2043;
        // border-top-left-radius: 10px;
        // border-top-right-radius: 10px;
        // border-bottom-left-radius: 10px;
        // border-bottom-right-radius: 10px;
    }
}

.content {
    position: relative;
    ;

    .ptype {
        position: sticky;
        color: #999999;
        margin-left: 10px;
        margin-bottom: 10px;
        display: flex;
        align-items: center;

        .ptyperadio {
            margin: 0 10px;
            padding: 10px 20px;
            color: #000;
            cursor: pointer;
        }

        .ptyperadio-active {
            background: rgba(254, 32, 67, 0.1206);
            color: #FE2043;
            border-radius: 10px;
        }
    }

    .container {
        column-count: 5;
        column-gap: 0;

        .div-item {
            break-inside: avoid;
            /* 防止在元素内部发生分页 */
            width: 100%;
            border: 1px solid #ccc;
            height: 100px;
        }

        /* 重新定义内容块排序优先级，让其横向排序 */

    }


    .page-main {
        background: #ffffff;
        overflow: hidden;
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-gap: 0;
        // grid-template-rows: masonry;

        .card-item {
            width: 100%;
            height: 100px; // 使用这个值作为 div 的高度;
            border: 1px solid #ccc;
        }

    }

    .scopeimg {
        width: 100%;
        border-radius: 16px;
        border: 1px solid #fff;
        box-sizing: border-box;
    }

    // 鼠标移入,添加灰色蒙版
    .scopeimg:hover {
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    }

    .card:hover .scopemask {
        visibility: visible;
    }

    .scopemask {
        visibility: hidden;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.4969);
        ;
        z-index: 1;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }

    .scopebtn {
        width: 100px;
        height: 40px;
        // background-color: #fff;
        color: red;
        border-radius: 20px;
        text-align: center;
        line-height: 40px;
        font-size: 16px;
        color: #000;
        cursor: pointer;
    }


}</style>