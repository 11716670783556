<template>
  <div class="container">
    <div class="header">
      <div class="back" @click="back">
        <img src="https://top.xhsbds.com/assets/img/back.png">
      </div>
      <div class="title">
        AI文字工具
      </div>
      <div class="sub-title">
        <!-- 火锅类 -->
        {{ cateName }}
      </div>
    </div> <br>

    <div class="content">


      <div class="desc">

        <p> 1.请选择赛道</p>
        <div class="tracks">
          <div class="box" v-for="(item, index) in track" :key="index" @click="handleClick(item)"
            :class="{ 'box-active': item.id == menuItem }">
            {{ item.name }}
          </div>

        </div>

        <p> 2.请选择生产方向</p>
        <div class="production">
          <div class="box" v-for="(item, index) in direction" :key="index" @click="handleClickDirect(item)"
            :class="{ 'box-active': item.id == menuItemD }">
            {{ item.name }}
          </div>
        </div>

        <div class="inc">
          <p> 3.请完善内容所包含的元素,AI帮您生成</p>

          <textarea class="more" v-model="more" resize="none" @input="handleInput" 
          placeholder="例如:今天去吃了一家很好吃的重庆火锅，地址在万象城4楼，菜品新鲜，服务周到，店面卫生干净，值得去吃！"></textarea>

          <div class="num" :class="{ 'red-text': isOverLimit }">{{ textLength }}/1000</div>


        </div>
      </div>



    </div>

    <div class="again-button" @click="send">
      生成内容
    </div>
  </div>

</template>

<script>
import { cateDetail } from '@/api/utils/index';


export default {
  props: ['tracks'],
  data() {
    return {
      menuItem: '',
      menuItemSelected: '',
      menuItemD: '',
      menuItemSelectedD: '',
      more: '',
      track: [],
      direction: [],
      cateName: "",
      msg:'',
    }
  },
  created() {
    this.msg = '例如:今天去吃了一家很好吃的重庆火锅，地址在万象城4楼，菜品新鲜，服务周到，店面卫生干净，值得去吃！';
  },
  computed: {
    textLength() {
      return this.more.length;
    },
    isOverLimit() {
      return this.textLength > 1000;
    },
  },

  methods: {
    ontype(item) {
      this.oncatetype = item;
      this.onCateDetail();
    },
    async init() {
      console.log(Math.floor(Date.now() / 1000));
      // 获取url的数据
      let id = this.$route.query.id;
      this.cateName = this.$route.query.name;

      if (id) {
        this.oncatetype = { id: id };
      } else {
        this.oncatetype = 6;
      }

      await this.onCateDetail();
    },
    async onCateDetail() {

      let list = await cateDetail({ id: this.oncatetype.id });
      // console.log(this.data, "列表数据");
      this.track = list.data.track.children;
      this.direction = list.data.direction.children;
      // this.cateName =name;
      // console.log(this.datalist, "列表数据");
    },
    handleClick(item) {
      this.menuItem = item.id;
      this.menuItemSelected = true;
      // item.style.border = '1px solid #FE2043';
      this.selectedBoxValue = item.name;
      // console.log(this.selectedBoxValue);

    },


    handleClickDirect(item) {
      this.menuItemD = item.id;
      this.menuItemSelectedD = true;
      // item.style.border = '1px solid #FE2043';
      // console.log(item);
      this.selectedBoxValueD = item.name;
      // console.log(this.selectedBoxValueD);
    },

    back() {
      window.history.back();
    },

    async send() {


      //判断是否有权限
      let isUse = await this.$utils.getaiCanUse();
      if(!isUse){
          this.$message.error('您没有权限使用AI功能，请检查是否登录/小程序开通套餐');
          return ;
      }


      if (this.selectedBoxValue && this.selectedBoxValueD) {
        // 选择得分类          //选择得赛道
        const track = this.selectedBoxValue;
        // 请选择生产方向
        const drict = this.selectedBoxValueD;

        let sendMsg = '';
        if (!this.more) {
          sendMsg = this.msg;
        }else{
          sendMsg = this.more;
        }

        // console.log(sendMsg,'Fa消息');

        // 在这里获取textarea的文字
        const textareaContent = sendMsg + ' 赛道是 ' + track + '，生产方向是 ' + drict;
        this.$router.push({
          path: '/TextResults',
          query: {
            cate: this.cateName,
            track: track,
            drict: drict,
            textareaContent: textareaContent,
          }

        })


      } else {
        if (!this.selectedBoxValue) {

          this.$message({
          message: '请先选择赛道',
          type: 'warning'
        });
         
          // alert('请先选择赛道');
        }else if (!this.selectedBoxValueD) {
          this.$message({
          message: '请先选择生产方向',
          type: 'warning'
        });
        }

      }


      //请求接口
      // console.log( 'Textarea内容:',textareaContent);
    },
    handleInput() {
      if (this.isOverLimit) {
        // 如果超过限制，截取前1000字符
        this.more = this.more.slice(0, 1000);
      }
    },

  },
  mounted() {
    this.init();
    // document.body.style.backgroundColor = '#FFF0F0';

  },

}
</script>

<style scoped>
/* .box {
  background-color: white;
  color: black;
} */

/* .box:hover {
  background-color: lightgray;
  color: #333333;
} */

/* .box.clicked {

  color: white;
  border: 1px solid red;

}  */


.box:hover {
  background-color: lightgray;
  color: black;
  border: 1px solid #FE2043;
}

.box-active {
  color: #FE2043;
  border: 1px solid #FE2043 !important;
  /* background: #FE2043; */
}



/* .box-active { */
/* color: #fff; */
/* border:1px solid #e0e0e0; */
/* background: #FE2043; */
/* } */


.header {
  display: flex;
  flex-direction: row;
  /* border: 1px red solid; */
}


.red-text {
  color: red;
}

.header .back {
  display: flex;
  width: 50px;
  height: 50px;
  opacity: 1;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
}


.title {
  margin-left: 2%;
  /* 设置右侧的间距为20像素 */
  font-size: 24px;
  font-weight: normal;
  line-height: 41px;
  letter-spacing: 0px;
  font-family: AlibabaPuHuiTi;
  color: #333333;
  opacity: 1;

}

.sub-title {
  display: flex;
  margin-left: 2%;
  /* 设置右侧的间距为20像素 */
  font-size: 16px;
  font-weight: normal;
  line-height: 19px;
  letter-spacing: 0px;
  color: #333333;
  opacity: 1;
  text-align: center;
  align-items: center;
  justify-content: center;

}

.content {
  /* border: 1px red solid; */
  position: relative;
}


.desc {
  position: relative;
  /* width: 1166px; */
  height: auto;
  border-radius: 24px;
  background-color: #ffffff;
  color: #666666;
  padding: 25px;
  /* border: 1px solid #918d8b; */
  box-shadow: 0px 0px 4px 0px #c31919;
}




.desc p {

  font-size: 14px;
  font-weight: normal;
  line-height: 29px;
  letter-spacing: 0px;
  color: #333333;
}

.desc .tracks {
  display: flex;
  /* border: 1px red solid; */
  flex-wrap: wrap;
  /* justify-content: center; */
}




.desc .box {
  display: flex;
  border: 1px rgb(117, 115, 115) solid;
  margin-left: 10px;
  position: relative;
  width: 192px;
  height: 50px;
  opacity: 1;
  border-radius: 24px;
  /* 添加以下样式以实现流式布局 */
  flex-basis: calc(20% - 20px);
  max-width: calc(20% - 20px);
  min-width: calc(20% - 20px);
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: auto;
  /* margin-right: 20px; */
  margin-top: 1%;
  align-items: center;
  justify-content: center;

}

.desc .production {
  display: flex;
  /* border: 1px red solid; */
  flex-wrap: wrap;

}

.desc .inc {
  /* border: 1px solid red; */
  width: auto;
  position: relative;
}

.num {
  position: absolute;
  right: 8px;
  bottom: 2px;
}

.desc .more {
  display: flex;
  border: 1px solid #CCCCCC;
  position: relative;
  height: 180px;
  width: 97%;
  border-radius: 24px;
  background-color: #ffffff;
  color: #666666;
  padding: 20px;
  resize: none;
  /* 禁止用户调整大小 */

}



.again-button {
  display: flex;
  margin-top: 20px;
  /* border: 1px red solid; */
  /* position: absolute; */
  left: 0px;
  top: 812px;
  /* width: 1166px; */
  height: 68px;
  border-radius: 15px;
  opacity: 1;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  background: linear-gradient(131deg, #FFD06E -28%, #FE2043 52%);
  font-size: 24px;

}

.container {
  width: 60%;
  min-width: 600px;
  margin: auto;
}</style>