<template>
        <div class="Titem-dom" @click="toEditurl(item)">
            <div style="    display: grid;    grid-template-columns: 1fr 1fr 1fr;margin: 35px 0;">
                <!-- <div :class="item.icon"></div> -->
                <img :src="item.img" class="Timg" />
                <div class="item2">
                    <div style="font-size: 24px;padding: 10px 0;">{{ item.name }}</div>
                    <div style="font-size: 14PX;">{{ item.intro ||"爆款文案等你来解锁" }}</div>
                </div>
            </div>
        </div>
</template>

<script>
export default {
    props:{
        item:{
            type:Object,
            default:()=>{
                return {
                    icon: "Catering-icon",
                    name: "餐饮类",
                    description: "爆款文案等你来解锁",
                }
            }
        }
    },
    methods: {
        toEditurl(item) {
            this.$router.push({
                path: '/TextEditor',
                query: {
                    id: item.id,
                    name: item.name
                }
            })
        }
    }
   
}
</script>

<style lang="less" scoped>
.Titem-dom {
    grid-template-columns: 1fr 1fr 1fr;
    display: grid;
    border: 1px solid #ffc2c2;
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &:hover {
        box-shadow: 0px 10px 20px 0px rgba(194, 145, 145, 0.677);
        color: #FE2043;
        border-radius: 20px;
    }
    .Timg{
        width: 90px;
        height: 90px;
        margin-left: 20px;
    }
    .item2 {
     grid-column: 2 / 4; /* 这里让第二个div跨越第二列和第三列 */
        margin-left: 20px;
}
}

</style>