import api from '@/api/newindex.js';
// import axios from 'axios'
import  { getFingerprint,JudgmentEnvironment } from '@/utils/utils.js';

/**
 * 
 * @param {  
 *    var params = {
        username:'test',
        password:'123456',
         level:2
    }} params 
 * @returns 
 */

/**
 登录
 */
async function login(params){
    params.device_info = await JudgmentEnvironment();
    params.device_id = await getFingerprint();

    // console.log("User-agent header sent: " +  JSON.stringify(params.device_info) );

    return await api.post('/user/login',params);
}
/** 
 * 退出
*/
async function loginto(params) {
    params.device_id = await getFingerprint();
    return await api.post('/user/login', params);
}
/**
 * 获取验证码
 * @param {*} params 
 * @returns 
 */
async function phonecode(params) {
    return await api.post('/user/phonecode', params);

}
/**
 * 验证码登录
 * @param {*} params 
 * @returns 
 */
async function loginCode(params) {
    params.device_info = await JudgmentEnvironment();
    params.device_id = await getFingerprint();
    return await api.post('/user/loginCode', params);

}

/**
 * 查询用户的个人信息
 */
async function getUserInfo() {
    let device_id = await getFingerprint();
    return await api.get(`/user/info?device_id=${device_id}`);
}
/**
 * 查询服务商等级
 */
async function shoplevel() {
    let device_id = await getFingerprint();
    return await api.post(`/shop/level?device_id=${device_id}`);
}
/**
 * 查询设备列表
 */
async function deviceList() {
    let device_id = await getFingerprint();
    return await api.post(`/user/deviceList`,{device_id});
}
/**
 * 根据id解绑设备
 */
async function deviceUnbind(params) {
    params.device_id = await getFingerprint();
    return await api.post(`/user/deviceUnbind`,params);
}
/**
 * 获取token短链接
 */
async function UserGoToken(params={}) {
    params.device_id = await getFingerprint();
    return await api.post(`/User/goToken`,params);
}
export {
    loginto,
    login,
    phonecode,loginCode,getUserInfo,shoplevel,deviceList,deviceUnbind,UserGoToken
}