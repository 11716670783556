<template>
    <div style="width: 100%;">
        <div class="card">
            <CradHome v-for="(item, i) in this.userMenu?.managerSystemList" :key="i" :item="item" />
        </div>
        <div style="margin: 40px 0 10px 0">
            <span style="font-weight: 400;font-size: 24px;">AI图片工具</span>
            <span style="font-size: 15px;color: #666666;margin-left: 15px;">快速创作出你想要的图片</span>
        </div>
        <div class="ptools">
            <CradPicture class="pitem" v-for="(item, i) in userMenu.picAI" :key="i" :item="item" />
            <div class="ptoolsMore" @click="toPictureTools()">
                <div class="moreENd">
                    <div class="move-icon"></div>
                    <div class="move-text">查看全部</div>
                </div>
            </div>
        </div>

        <div style="margin: 40px 0 10px 0">
            <span style="font-weight: 400;font-size: 24px;">AI文字工具</span>
            <span style="font-size: 15px;color: #666666;margin-left: 15px;">轻松搞定爆款文案</span>
        </div>

        <div class="Ttools">
            <!-- <CradPicture class="Titem" v-for="(item, i) in userMenu.picAI" :key="i" :item="item" /> -->
            <div class="Titem">
                <CradText v-for="(item, i) in userMenu.textAI" :key="i" :item="item" />
            </div>
            <div class="ptoolsMore" @click="toTextTools()">
                <div class="moreENd">
                    <div class="move-icon"></div>
                    <div style="" class="move-text">查看全部</div>
                </div>
            </div>
        </div>
        <div style="margin: 40px 0 10px 0">
            <span style="font-weight: 400;font-size: 24px;">图片模板</span>
            <span style="font-size: 15px;color: #666666;margin-left: 15px;">海量模版 创作不用愁</span>
        </div>
        <IndexTemplate :listdata="homeTemplate"></IndexTemplate>
    </div>
</template>

<script>
// 创客贴
import { utlisApi } from "@/utils/utils";
// import CktDesign from "@chuangkit/chuangkit-design";//在需要使用的页面引入，并实例化对象
// 模块
import CradHome from '@/components/CradHome';
import CradPicture from '@/components/CradPicture/indexhome.vue';
import IndexTemplate from '@/components/IndexTemplate';
import CradText from '@/components/CradText/indexhome.vue';
// 接口数据
import { getHomeTemplate, toindex } from '@/api/utils/index';

export default {
    name: 'newtemplate',
    components: {
        CradHome, CradPicture, IndexTemplate, CradText
    },
    data() {
        return {
            utlisApi: new utlisApi(),
            // CktDesign: new CktDesign(),
            userMenu: {
                managerSystemList: [
                    {
                        "icon": "potato-icon",
                        "image": "https://top.xhsbds.com/assets/img/index/fws.png",
                        "value": "本地薯服务商共创平台",
                        "description": "为本地菌服务商提供优质服务",
                        "link": "进入"
                    },
                    {
                        "icon": "travel-icon",
                        "image": "https://top.xhsbds.com/assets/img/index/jlgl.png",
                        "value": "酒旅服务商共创平台",
                        "description": "为酒旅服务商提供优质服务",
                        "link": "https://bds.timesaas.cn/#/login"
                    },
                    {
                        "icon": "lifestyle-icon",
                        "image": "https://top.xhsbds.com/assets/img/index/bdsh.png",
                        "value": "本地生活服务商管理后台",
                        "description": "为本地生活服务商提供优质服务",
                        "link": "https://cj-mall.kaidang.cn/index.php?s=/store/passport/login/service/bds"
                    },
                    {
                        "icon": "experts-icon",
                        "image": "https://top.xhsbds.com/assets/img/index/hydr.png",
                        "value": "为达人提供优质服务",
                        "description": "达人招募服务中心",
                        "link": "进入"
                    }
                ],
                picAI: [{
                    img: "https://top.xhsbds.com/assets/img/aiPictureTools/twqc.png",
                    name: "图文去重",
                    onitem: () => {
                        console.log("点击了图文去重");
                        this.$router.push({
                            path: '/pImageRemoval',
                        })
                    }
                }, {
                    img: "https://top.xhsbds.com/assets/img/aiPictureTools/aikt.png",
                    name: "AI扩图",
                    onitem: async () => {

                        //判断是否有权限
                        let isUse = await this.$utils.getaiCanUse();
                        if (!isUse) {
                            this.$message.error('您没有权限使用AI功能，请检查是否登录/小程序开通套餐');
                            return;
                        }
                        console.log("点击了AI扩图配图");
                        this.$router.push({
                            path: '/pictrueMod',
                        })
                    }
                }, {
                    img: "https://top.xhsbds.com/assets/img/aiPictureTools/wsgcc.png",
                    name: "智能设计",

                }, {
                    img: "https://top.xhsbds.com/assets/img/aiPictureTools/xhszt.png",
                    name: "小红书配图",
                    onitem: () => {
                        console.log("点击了小红书配图");
                        this.$router.push({
                            path: '/xhs',
                        })
                    }
                }, {
                    img: "https://top.xhsbds.com/assets/img/aiPictureTools/znkt.png",
                    name: "智能抠图",
                },],
                textAI: [{
                    id: 1,
                    icon: "Catering-icon",
                    name: "餐饮类",
                    description: "爆款文案等你来解锁",
                }, {
                    id: 2,
                    icon: "hotel-icon",
                    name: "酒旅类",
                    description: "吸睛的文案都在这里",
                }, {
                    id: 3,
                    icon: "entertainment-icon",
                    name: "休闲娱乐",
                    description: "你想不到的我都能写出",
                }, {
                    id: 4,
                    icon: "Ecommerce-icon",
                    name: "电商类",
                    description: "营销文案不用愁",
                },],
            },
            homeTemplate: [],//首页模板
        }
    },
    mounted() {
        this.init();
    },
    watch: {
        loginRole() {
            this.someMethod();
        },
        // 监听计算属性 userInfoFromStore 的变化，当其变化时更新 userInfo 的值
        userInfoFromStore: {
            deep: true,
            immediate: true,
            async handler(newValue) {
                //如果newValue.identity是空的则不请求
                if(newValue.identity){
                    let level = newValue.identity == 4 ? 2 : 3;
                    let resMenu = await toindex({ level: level });
                    this.userMenu.managerSystemList = resMenu.data.managerSystemList;
                }

            },
        },
    },
    computed: {
        loginRole() {
            return this.$store.state.loginRole;
        },
        // 计算属性 userInfoFromStore 监听 this.$store.state.userInfo 的变化
        userInfoFromStore() {
            return this.$store.state.userInfo;
        },
    },
    methods: {
        async someMethod() {
            // 你需要重新调用的方法的代码
            await this.init();
        },

        async init() {

            // await this.$newapi.shoplevel();
            // await this.$newapi.getUserInfo();
            const role = localStorage.getItem("loginRole");
            let resMenu = await toindex({ level: role });
            // console.log(resMenu,"toindex");
            // let originData = resMenu.data.managerSystemList;

            this.userMenu.managerSystemList = resMenu.data.managerSystemList;
            // console.log(originData,"origonData");



            var res = await getHomeTemplate();
            this.homeTemplate = res.data;
            // console.log(res.data, "this.homeTemplate");
        },
        toTextTools() {
            //TODO：该模块最终会在展示
            this.$store.commit('setMenuItems', 3);
            this.$router.push({
                path: '/AITextTool',
            })
        }
        , toPictureTools() {
            //TODO：该模块最终会在展示
            this.$store.commit('setMenuItems', 2);
            this.$router.push({
                path: '/AIPictureTool',
            })
        }
    },
}
</script>

<style lang="less" scoped>
.card {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
}

.ptools {
    display: grid;
    grid-template-columns: repeat(11, 1fr);

    grid-gap: 30px;

    .pitem:nth-child(-n+10) {
        grid-column: span 2;
        cursor: pointer;
    }


}


.ptoolsMore {

    // border: 1px solid #ffc2c2;
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    .moreENd {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        .move-text {
            font-size: 16px;
            // color: #333333;
            margin-top: 10px;
        }
    }

    &:hover {
        box-shadow: 0px 10px 20px 0px rgba(194, 145, 145, 0.677);
        // color: #FE2043;
        border-radius: 20px;

    }


}

.Ttools {
    display: grid;
    grid-template-columns: repeat(11, 1fr);

    grid-gap: 30px;

    .Titem:nth-child(-n+10) {
        grid-column: span 10;
    }

    .Titem {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 30px;
    }
}
</style>
