import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes";
import store from "@/store/store";

Vue.use(VueRouter); // 使用一个vue插件

const router = new VueRouter({
    // 配置
    routes, // 路由匹配规则
    // mode: "history",
});


// 添加路由守卫
router.beforeEach((to, from, next) => {

    try {
        let is_login = localStorage.getItem('token');

        if (is_login) {

            if (to.path == '/') {
                next('/index')
            } else {
                if (to.path === '/index') {
                    store.commit('setMenuItems', 1);
                } else if (to.path === '/AIPictureTool') {
                    store.commit('setMenuItems', 2);
                } else if (to.path === '/AITextTool') {
                    store.commit('setMenuItems', 3);
                } else if (to.path === '/FreeTools') {
                    store.commit('setMenuItems', 4);
                }

                next()
            }
        } else {
            if (to.path != '/login') {
                next('/login')
            } else {
                next()
            }
        }
    } catch (e) {
        console.log(e)
    }

})

export default router;
