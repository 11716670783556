import Vue from 'vue'
import '../public/config.js'
import App from './App.vue'
import router from "./router";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import Vuex from 'vuex'
import store from '@/store/store.js'
import '../static/css/global.css' /*引入公共样式*/
import * as newapi from '@/api/utils/newindex.js'
import * as utils from "@/utils/utils";
// 将public/config.js中的配置文件地址导入到window.appConfig中


// import CktDesign from "@chuangkit/chuangkit-design";
Vue.config.productionTip = false;
Vue.prototype.$newapi = newapi;
Vue.prototype.$utils = utils;
Vue.prototype.$store = store;

Vue.use(Vuex)
Vue.use(ElementUI);

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
