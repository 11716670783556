<template>
  <div id="app">
    <div class="top">
      <div
        class="menuitem"
        @click="showFreeTools"
        :class="{ 'menuitem-active': currentArray == 1 }"
      >
        免费图片工具
        <div
          v-if="currentArray == 1"
          style="
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            width: 20px;
            border-radius: 10px;
            background: rgb(254, 32, 67);
            height: 5px;
          "
        ></div>
        <div v-else style="height: 5px; margin-top: 5px"></div>
      </div>
      <div
        class="menuitem"
        @click="showFreeToolsList"
        :class="{ 'menuitem-active': currentArray == 2 }"
      >
        免费文案工具
        <div
          v-if="currentArray == 2"
          style="
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            width: 20px;
            border-radius: 10px;
            background: rgb(254, 32, 67);
            height: 5px;
          "
        ></div>
        <div v-else style="height: 5px; margin-top: 5px"></div>
      </div>
    </div>

    <div v-if="currentArray === 1">
      <div class="container">
        <div
          class="box"
          v-for="(item, index) in freeTools"
          :key="index"
          @mouseenter="onMouseEnter"
          @mouseleave="onMouseLeave"
          @click="handleLinkClick(item)"
        >
          <img :src="item.icon" alt="美图设计室" />
          <div >
            <h3>{{ item.name }}</h3>
            <p>{{ item.intro }}</p>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="currentArray === 2">
      <div class="container">
        <div
          class="box"
          v-for="(item, index) in freeToolsList"
          :key="index"
          @click="handleLinkClick(item)"
        >
          <img :src="item.icon" alt="美图设计室" />
          <div >
            <h3>{{ item.name }}</h3>
            <p>{{ item.intro }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { getTools } from "@/api/utils/index";

export default {
  data() {
    return {
      currentArray: 1,

      temp: 1,
      freeTools: [],
      freeToolsList: [],
    };
  },
  mounted() {
    this.init();
    // document.body.style.backgroundColor = '#FFF0F0';
  },
  methods: {
    init() {
      getTools({ level: 2 }).then((res) => {
        // 处理getTools返回的数据
        this.freeTools = res.data?.toolList?.thirdToolList;
        this.freeToolsList = res.data?.toolList?.textToolList;
      });
    },

    showFreeTools() {
      this.currentArray = 1;
    },
    showFreeToolsList() {
      this.currentArray = 2;
    },
    onMouseEnter() {
      // this.$refs.videoContainer.add('hovered');
    },
    onMouseLeave() {
      // this.$refs.videoContainer.remove('hovered');
      this.$refs.videoContainer.classList.add("hovered");
    },
    // onMouseLeave() {
    //   this.$refs.videoContainer.classList.remove("hovered");
    // },
    handleLinkClick(item) {
      window.open(item.url, "_blank");
    },
  },
};
</script>

<style lang="less" scoped>
// @import url("https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap");
.container{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
}

#app {
  min-height: 400px;
}

.top {
  display: flex;
  flex-direction: row;
  /* justify-content: space-between;  */
  /* 新增：将内容均匀分布 */
}

.menuitem {
  margin-right: 20px;
  /* 设置右侧的间距为20像素 */
  /* 修改此处数值以设置您需要的字体大小 */
  /* font-size: 16px;  */
}

.menuitem:hover {
  color: #fe2043;
  /* 将字体颜色改为红色 */
  text-decoration-color: #fe2043;
  /* 将下划线颜色改为红色 */
}

.menuitem-active {
  /* color: #fff; */
  /* border:1px solid #e0e0e0; */
  /* background: #FE2043; */

  color: #fe2043;
  /* 将字体颜色改为红色 */
  position: relative;
  text-decoration-color: #fe2043;
  /* 将下划线颜色改为红色 */
}

.box {
  /* border: 1px solid red; */
  position: relative;
  float: left;
  width: 100%;
  height: 170px;
  border-radius: 24px;
  opacity: 1;
  background-color: #ffffff;
  margin-top: 10px;
  // margin-right: 18px;
  // margin-bottom: 10px;
  // margin-top: 20px;
  box-sizing: border-box;
  text-align: left;
  padding: 20px;
  border: 1px solid #ffc2c2;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  a {
    text-decoration: none;
    color: #0c0b0b;
  }
  h3 {
    width: 120px;
    height: 29px;
    opacity: 1;
    font-family: PingFangSC;
    font-size: 24px;
    font-weight: normal;
    line-height: 29px;
    letter-spacing: 0px;
    color: #0c0b0b;
    margin: 0px;
  }
  p {
    width: 143px;
    height: 17px;
    opacity: 1;
    font-family: PingFangSC;
    font-size: 14px;
    font-weight: normal;
    line-height: 17px;
    letter-spacing: 0px;
    color: #c4bbbd;
  }
  img {
    width: 85px;
    height: 85px;
    opacity: 1;
  }
  &:hover {
    box-shadow: 0px 10px 20px 0px rgba(194, 145, 145, 0.677);
    /* 添加阴影效果 */
    cursor: pointer;
    /* 改变鼠标指针为手指形状 */
  }
}


.box-active {
  /* color: #fff; */
  /* border:1px solid #e0e0e0; */
  /* background: #FE2043; */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  /* 添加阴影效果 */
  cursor: pointer;
  /* 改变鼠标指针为手指形状 */
}
</style>